import React from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

const ObjectIsActive = ({ object }) => {
  const color = object.active ? 'green' : 'red'

  return (
    <div
      className={`bg-${color}-100 border border-${color}-300 my-auto flex h-9 space-x-2 rounded-md px-3`}
    >
      <span className="flex flex-col justify-center">
        <CheckCircleIcon className={`h-5 w-5 text-${color}-500`} />
      </span>
      <div
        className={`hidden flex-col justify-center text-${color}-900 md:flex`}
      >
        <span>{object.active ? 'Active' : 'Inactive'}</span>
      </div>
    </div>
  )
}

export default ObjectIsActive

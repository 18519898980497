import React, { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { Trans } from 'react-i18next'
import { useQueryParam } from 'hooks/useQueryParam'
import { XCircleIcon, Bars3Icon } from '@heroicons/react/24/outline'
import { FixedSizeList } from 'react-window'

const TabContent = ({ component, onMount = () => {}, ...props }) => {
  const Content = component

  useEffect(() => {
    onMount()
    // Do not add missing dependency onMount in the dependency array, it will cause rerenders.
  }, [])

  return <Content {...props} />
}

const VerticalTabbedCard = ({ children, className }) => {
  //  --- Variables ---
  const [tabBarOpen, setTabBarOpen] = useState(true)
  const [currentTab, setCurrentTab] = useQueryParam('tab', children[0].key)
  const tabsRef = useRef()

  // we need to keep track of preloaded tabs, otherwise async changes in tab content kan cause unintended "jumping" between tabs
  const [preloadedTabs, setPreloadedTabs] = useState([])

  const onTabMount = useCallback(
    (tabKey) => {
      setPreloadedTabs((p) => [...new Set([...p, tabKey])])
    },
    [setPreloadedTabs]
  )

  const switchTabBar = () => {
    setTabBarOpen((t) => !t)
  }

  const Row = ({ index, style }) => {
    const child = children[index]
    const active = currentTab === child.key
    return (
      <div style={style}>
        <span
          onClick={() => setCurrentTab(child.key)}
          className={cn(
            active
              ? 'bg-gray-100 text-gray-900'
              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
            'flex w-full cursor-pointer items-center rounded-md px-3 py-2 text-sm font-medium'
          )}
          aria-current={active ? 'tab' : undefined}
        >
          <span className="truncate">
            <Trans i18nKey={child.i18n}>{child.heading}</Trans>
          </span>
        </span>
      </div>
    )
  }

  //  --- Response ---
  return (
    <div
      className={`flex h-full w-full overflow-hidden bg-white md:rounded-2xl md:shadow-xl ${className}`}
    >
      <nav aria-label="Sidebar" className="flex flex-col border-r">
        {tabBarOpen ? (
          <div className="flex w-full justify-end px-6 pt-3">
            <button
              onClick={switchTabBar}
              className="outline-none focus:outline-none"
            >
              <XCircleIcon className="h-6 w-6 text-gray-800" />
            </button>
          </div>
        ) : (
          <div className="flex flex-col space-y-2 px-2 pt-3">
            <button
              onClick={switchTabBar}
              className="outline-none focus:outline-none"
            >
              <Bars3Icon className="h-6 w-6 text-gray-800" />
            </button>
          </div>
        )}

        <div
          className={cn('h-full', {
            // Do not use "hidden", as that will set the tabRef scroll height to zero.
            // We need to maintain the scroll height, but get rid of the width.
            'invisible w-0': !tabBarOpen,
          })}
        >
          {/* 
            This wraps the reference div (tabsRef) to maintain relative width and padding.
            Div would overflow to match children length without.
           */}
          <div className={'flex h-full w-56 flex-col py-3.5 px-6'}>
            {/* Reference div needs to stretch, otherwise scroll hight will be 0 */}
            <div ref={tabsRef} className="h-full w-full">
              {tabsRef.current != null && (
                <FixedSizeList
                  itemCount={children.length}
                  itemSize={40}
                  height={tabsRef.current.scrollHeight}
                >
                  {Row}
                </FixedSizeList>
              )}
            </div>
          </div>
        </div>
      </nav>

      <div className="grid h-full w-full grid-cols-1 py-5 px-4 md:rounded-b-2xl">
        <div className="col-span-1">
          {children.map((child) => {
            return (
              (currentTab === child.key ||
                (child.preload && !preloadedTabs.includes(child.key))) && (
                <div
                  key={`${child.key}_content`}
                  className={
                    currentTab === child.key ? 'h-full' : 'hidden h-full'
                  }
                >
                  <TabContent
                    {...child.props}
                    onMount={(e) => onTabMount(child.key)}
                    component={child.component}
                  />
                </div>
              )
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default VerticalTabbedCard

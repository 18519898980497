import React, { useState } from 'react'
import { CheckIcon, PencilIcon } from '@heroicons/react/24/solid'
import { XIcon } from '@heroicons/react-v1/solid'

import ApiHelper from 'helpers/ApiHelper'

const ObjectNameInput = ({ object, ...props }) => {
  //  --- Variables ---
  const [name, setName] = useState(object.name)
  const [actualName, setActualName] = useState(object.name)
  const [isEditable, setIsEditable] = useState(false)

  //  --- Functionality ---
  const confirmEdit = async () => {
    await ApiHelper.updateObject(object.objectId, { ...object, name })
    setIsEditable((prevState) => !prevState)
    setActualName(name)
  }

  const cancelEdit = () => {
    setIsEditable((prevState) => !prevState)
    setName(actualName)
  }

  //  --- Response ---
  if (!isEditable)
    return (
      <div className="flex flex-row space-x-3">
        <p
          className="my-auto py-1 pr-3 text-xl font-semibold"
          style={{ whiteSpace: 'nowrap' }}
        >
          {actualName}
        </p>
        <PencilIcon
          className="w-8 cursor-pointer rounded px-2 hover:bg-gray-100"
          onClick={() => setIsEditable((prevState) => !prevState)}
        />
      </div>
    )
  return (
    <div className="flex flex-row space-x-3">
      <input
        className="w-64 px-3 font-semibold sm:w-48"
        value={name}
        onChange={(e) => setName(e.target.value)}
        autoFocus={true}
      />
      <div className="flex flex-row space-x-3">
        <CheckIcon
          className="w-8 cursor-pointer rounded px-2 text-green-600 hover:bg-gray-100"
          onClick={confirmEdit}
        />
        <XIcon
          className="w-8 cursor-pointer rounded px-2 text-red-600 hover:bg-gray-100"
          onClick={cancelEdit}
        />
      </div>
    </div>
  )
}

export default ObjectNameInput

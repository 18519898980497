const LogoutView = () => {
  console.log('Logout page')
  if (localStorage.getItem('authStatus')) {
    localStorage.setItem('authStatus', 'false')
  }
  window.location = '/api/users/logout'
  return null
}

export default LogoutView

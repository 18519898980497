import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Card, FilteredTable } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { prefetchQuery } from 'helpers/QueryHelper'
import UserTypeIcon from 'components/shared/Users/UserTypeIcon'

const AdminObjectUsersIndexView = () => {
  //  --- Variables ---
  let history = useHistory()
  const { t } = useTranslation()

  //  --- Response ---
  return (
    <Card cardType="primary-content" className="mb-4 h-full">
      <FilteredTable
        apiName="indexObjectUsers"
        dataApi={() => ApiHelper.indexObjectUsers()}
        filterCacheKey="indexObjectUser"
        initialSort={{ id: 'userName', desc: false }}
        placeholder={t('application:search')}
        removeFooter={true}
        scrollRestore={true}
        filterDebounce={true}
        rowOnClick={(row) => {
          prefetchQuery('user', row.sid, row)
          history.push(`/access/users/${row.userId}/${row.sid}`)
        }}
        tableHeaders={tableHeaders}
      />
    </Card>
  )
}

const tableHeaders = [
  // {
  //   Header: 'ID',
  //   i18n: 'indexObjects:objectUsersTable.id',
  //   accessor: 'userId',
  //   width: 70,
  // },
  {
    Header: 'Username',
    i18n: 'indexObjects:objectUsersTable.username',
    accessor: 'userName',
    width: 400,
  },
  {
    Header: 'Display Name',
    i18n: 'indexObjects:objectUsersTable.displayName',
    accessor: 'displayName',
    width: 300,
  },
  {
    Header: 'Type',
    i18n: 'indexObjects:objectUsersTable.type',
    accessor: 'userTypeId',
    width: 60,
    Cell: (props) => (
      <UserTypeIcon userTypeId={props.row.original.userTypeId} />
    ),
  },
  {
    Header: 'Tenant',
    i18n: 'indexObjects:objectUsersTable.tenant',
    accessor: 'tenantName',
    width: 100,
  },
]

export default AdminObjectUsersIndexView

import { useState } from 'react'
import cn from 'classnames'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import nb from 'date-fns/locale/nb'
import { format } from 'date-fns'

registerLocale('nb', nb)

/**
 * TODO: This DateInput needs to be replaced. It currently uses a weird system of having an initial
 * value and an onChange, but not a value prop.
 *
 * This probably comes from us not really passing around true date objects, but rather strings.
 */

const DateInput = ({
  initialValue,
  onChange,
  showTimeSelect = true,
  ...props
}) => {
  // const defaultValue = initialValue ? dayjs(initialValue).toDate() : null
  const defaultValue = initialValue ? new Date(initialValue) : null
  const [date, setDate] = useState(defaultValue)

  const dateChange = (d) => {
    // d is a Date object from DatePicker.
    // We set the full date to the state,
    // But only pass a formatted string to the onChange function
    setDate(d)
    onChange(d ? new Date(d).toISOString() : null)
  }

  return (
    <DatePicker
      locale="nb"
      isClearable
      className="focus:outline-none h-9 rounded-lg border bg-white px-3 focus:border-blue-200"
      defaultValue={date}
      selected={date}
      onChange={dateChange}
      // showTimeSelect={showTimeSelect}
      showTimeInput={showTimeSelect}
      dateFormat="yyyy-MM-dd HH:mm"
      timeFormat="HH:mm"
      {...props}
    />
  )
}

export function isInvalidDate(date) {
  return date.toString() === 'Invalid Date'
}

export function BasicDateInput({
  onChange,
  defaultValue,
  className,
  ...props
}) {
  // const [date, setDate] = useState(new Date(defaultValue))
  const [isInvalid, setIsInvalid] = useState(
    isInvalidDate(new Date(defaultValue)) ?? false
  )

  function formatToString(date) {
    return format(date, 'yyyy-MM-dd HH:mm:ss')
  }

  // const isInvalid = isInvalidDate(date)

  return (
    <input
      className={cn(
        'focus:outline-none h-9 rounded-lg bg-white px-3',
        isInvalid
          ? 'border-2 border-red-400 focus:border-red-500'
          : 'border focus:border-blue-200',
        className
      )}
      placeholder="yyyy-MM-dd HH:mm"
      defaultValue={formatToString(defaultValue)}
      onChange={(e) => {
        if (e.target.value) {
          const iiv = isInvalidDate(new Date(e.target.value))
          setIsInvalid(iiv)
        }
      }}
      // onChange={(e) => {
      //   let value = e.target.value
      //   onChange(value)

      //   // setIsInvalid(isInvalidDate(value))

      //   // try {
      //   //   const newDate = new Date(value)
      //   //   // setIsInvalid(isInvalidDate(newDate))
      //   //   if (isInvalidDate(newDate)) {
      //   //     setIsInvalid(true)
      //   //   } else {
      //   //     onChange(value)
      //   //     setIsInvalid(false)
      //   //   }
      //   // } catch (error) {
      //   //   console.log('failed!' + error)
      //   //   setIsInvalid(true)
      //   // }

      //   // try {
      //   //   const newDate = new Date(value)
      //   //   setDate(newDate)
      //   // } catch (error) {
      //   //   //
      //   // }
      // }}
      {...props}
    />
  )
}

export default DateInput

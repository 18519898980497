import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import { Icon } from '../'

const NavbarItem = ({ item, indent = false, ...props }) => {
  //  --- Variables ---
  useTranslation()

  //  --- Response ---
  return item.submenu ? (
    <div className="nav-item pt-3 text-gray-600 md:pt-4" {...props}>
      <div className="mb-2">
        <Trans i18nKey={item.i18n}>{item.title}</Trans>
      </div>
      {item.submenu.map((i) => (
        <NavbarItem item={i} key={i.href} indent={true} />
      ))}
    </div>
  ) : (
    <div className="nav-item mb-1 text-gray-600" {...props}>
      <NavLink
        activeClassName="bg-gray-100 text-black"
        className={`flex flex-row rounded-md py-2 hover:bg-gray-200 ${
          props.indent ? 'pl-4' : 'pl-1'
        }`}
        to={item.href}
      >
        <div className="inline-flex">
          <Icon color="" size={4} iconName={item.icon} className="mr-1" />
          <div className="flex flex-col justify-center">
            <Trans i18nKey={item.i18n}>{item.title}</Trans>
          </div>
        </div>
      </NavLink>
    </div>
  )
}

export default NavbarItem

import { Menu } from '@headlessui/react'
import cn from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import Spinner from '../Animations/Spinner'

const MenuButton = ({
  title,
  isLoading = false,
  options = [],
  small = false,
  pinRight = true,
}) => {
  return (
    <div
      className={`max-w-56 text-right ${!small ? 'relative inline-block' : ''}`}
    >
      <Menu>
        <div>
          <Menu.Button
            className={`inline-flex w-full justify-center ${
              small ? 'h-7 px-2' : 'px-4 py-1'
            } focus:outline-none whitespace-nowrap rounded-md border bg-gray-100 hover:bg-gray-300 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            {isLoading && <Spinner height="h-6" width="w-6" className="mr-1" />}
            {title}
            <ChevronDownIcon
              className="my-auto ml-2 -mr-1 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Menu.Items
          className={`absolute ${pinRight ? 'right-0' : ''} z-50 ${
            small ? 'mr-5 w-40' : 'mt-2 w-56'
          } max-h-96 origin-top-right overflow-y-scroll rounded-md border border-gray-100 bg-white shadow-lg `}
        >
          <div className="px-1 py-1 ">
            {options.map((menuItem) => (
              <Menu.Item key={menuItem.key}>
                {({ active }) => (
                  <button
                    onClick={menuItem.onClick}
                    className={cn(
                      active ? 'bg-green-500 text-white' : 'text-gray-900',
                      'group flex w-full items-center rounded-md px-2 py-2 text-sm'
                    )}
                  >
                    <div className="flex flex-col items-start text-left">
                      <span
                        className={cn({
                          'font-semibold': menuItem.description,
                        })}
                      >
                        {menuItem.title}
                      </span>
                      {menuItem.description ? (
                        <div
                          className={cn(
                            'w-full text-xs',
                            active ? 'text-green-50' : 'text-gray-600'
                          )}
                        >
                          {menuItem.description}
                        </div>
                      ) : undefined}
                    </div>
                    {/* <div className="flex w-full flex-col items-start border">
                      <span>{menuItem.title}</span>
                      <span className="text-xs text-gray-600">Whoop</span>
                    </div> */}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  )
}

export default MenuButton

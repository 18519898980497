import { EllipsisHorizontalIcon, StarIcon } from '@heroicons/react/24/solid'
import cn from 'classnames'

export function FavouriteControl({ isFavourite, onClick, submitting = false }) {
  const toggleFavourite = () => {
    onClick()
  }

  return (
    <div
      onClick={toggleFavourite}
      className={cn(
        'my-auto flex h-9 space-x-2 rounded-md border bg-gray-100 px-3 hover:bg-gray-200',
        submitting ? 'cursor-not-allowed' : 'cursor-pointer'
      )}
    >
      <span className="flex flex-col justify-center">
        {submitting ? (
          <EllipsisHorizontalIcon className="h-5 w-5 animate-pulse text-gray-500" />
        ) : (
          <StarIcon
            className={cn(
              `h-5 w-5`,
              isFavourite ? 'text-green-400' : 'text-gray-400'
            )}
          />
        )}
      </span>
    </div>
  )
}

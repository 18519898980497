import React, { useState, useEffect } from 'react'
import ApiHelper from 'helpers/ApiHelper'
import { useTranslation } from 'react-i18next'
import { DateInput } from 'components'
import { useQuery } from '@tanstack/react-query'
import { Spinner, Button, MenuButton, LineGraph } from '../../components/shared'
import useFileDownload from 'helpers/FileDownloadHook'
import { getDate } from 'helpers/DateTimeHelper'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { useQueryParam } from 'hooks/useQueryParam'

const ObjectTelemetryGraphs = ({ id, ...props }) => {
  //  --- Variables ---
  const { t } = useTranslation()
  const [fromDate, setFrom] = useQueryParam('from', getDate('yesterday'))
  const [toDate, setTo] = useQueryParam('to', getDate('yesterday'))
  const queryProps = props.queryProps
  const showRefreshButton = true
  const [rows, setRows] = useState([])
  const { fetchFile } = useFileDownload()

  //  --- Response ---
  const { data, isFetching, isLoading, refetch } = useQuery(
    [`indexObjectTelemetries`, id, fromDate, toDate],
    () => ApiHelper.indexObjectTelemetry(id, fromDate, toDate),
    queryProps
  )
  //  --- Setup ---
  useEffect(() => {
    if (data === undefined) {
      setRows([])
    } else {
      setRows(data.reverse())
    }
  }, [data])

  //  --- Response ---
  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-row justify-end space-x-2 md:flex-grow">
          {isLoading || isFetching ? (
            <div className="flex">
              <Spinner className="my-auto mr-5" />
            </div>
          ) : null}

          <DateInput onChange={(d) => setFrom(d)} initialValue={fromDate} />
          <ArrowRightIcon className="h-full w-5 p-1" />
          <DateInput onChange={(d) => setTo(d)} initialValue={toDate} />

          {showRefreshButton ? (
            <Button onClick={refetch}> {t('application:refresh')} </Button>
          ) : null}
          {data != null && props.downloadUrls ? (
            <MenuButton
              title={t('application:download')}
              options={[
                {
                  key: 'json',
                  title: `${t('application:download')} JSON`,
                  onClick: () =>
                    fetchFile(props.downloadUrls.json, 'export.json'),
                },
              ]}
            />
          ) : null}
        </div>
      </div>
      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      ) : rows.length > 0 ? (
        <div className="mt-4 h-96 space-y-8 overflow-y-auto">
          <div className="h-96 w-full">
            <LineGraph
              data={rows}
              series="adc1"
              domain="sampleTime"
              seriesColour="#82ca9d"
              min={true}
              max={true}
              title="ADC 1 (mm)"
            />
          </div>
          <div className="flex h-96 w-full flex-row">
            <div className="w-1/3">
              <LineGraph
                data={rows}
                series="temperature"
                domain="sampleTime"
                seriesColour="#82ca9d"
                min={true}
                max={true}
                title={`${t('showObject:graphs.temperature')} (deg °C)`}
              />
            </div>
            <div className="w-1/3">
              <LineGraph
                data={rows}
                series="battery"
                domain="sampleTime"
                seriesColour="#82ca9d"
                min={true}
                max={true}
                title={`${t('showObject:graphs.battery')} (V)`}
              />
            </div>
            <div className="w-1/3">
              <LineGraph
                data={rows}
                series="accellerationZ"
                domain="sampleTime"
                seriesColour="#82ca9d"
                min={true}
                max={true}
                title={`${t('showObject:graphs.accelleration')} Z`}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <p>Nothing to display</p>
        </div>
      )}
    </div>
  )
}

export default ObjectTelemetryGraphs

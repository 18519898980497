import { format } from 'date-fns'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(utc)
dayjs.extend(relativeTime)

const currentDateTime = (formatString = 'yyyy-MM-dd HH:mm:ss') => {
  return format(new Date(), formatString)
}

// TODO: Not currently used
const getTime = () => {
  return format(new Date(), 'HH:mm')
}

// TODO: Not currently used
const formatDateAsYear = (date) => {
  return format(new Date(date), 'yyyy-MM-dd')
}

const getDate = (date) => {
  switch (date) {
    case 'yesterday':
      const yesterday = dayjs()
        .startOf('day')
        .subtract(1, 'days')
        .format('YYYY-MM-DD')

      return yesterday

    case 'thisMorning':
      const thisMorning = dayjs().startOf('day')
      return thisMorning

    case 'lastWeek':
      return dayjs().subtract(1, 'week').format('YYYY-MM-DD')

    case 'lastMonth':
      return dayjs().subtract(1, 'month').format('YYYY-MM-DD')

    case 'yearStart':
      return dayjs().startOf('year').format('YYYY-MM-DD')

    case 'default':
      return dayjs()
        .endOf('day')
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .format('YYYY-MM-DD')

    default:
      const today = dayjs()
        .endOf('day')
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .format('YYYY-MM-DD')

      return today
  }
}

const toLocalTime = (dateTime, format = 'YYYY-MM-DD HH:mm:ss') => {
  return dateTime
    ? dayjs.utc(dateTime.substring(0, 23)).local().format(format)
    : '-'
}

const toLocalTimeWithMilliseconds = (dateTime) =>
  toLocalTime(withOrWithoutZ(dateTime), 'YYYY-MM-DD HH:mm:ss.SSS')

function withOrWithoutZ(dateTime) {
  if (dateTime.slice(-1) === 'Z') {
    return dateTime
  }

  return dateTime + 'Z'
}

export {
  currentDateTime,
  getDate,
  getTime,
  toLocalTime,
  toLocalTimeWithMilliseconds,
  formatDateAsYear,
  withOrWithoutZ,
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FormBuilder, Spinner } from 'components'
import { Formik } from 'formik'
import * as yup from 'yup'
import ApiHelper from 'helpers/ApiHelper'
import toast from 'react-hot-toast'
import { useQuery } from '@tanstack/react-query'

const VippsCredentials = ({ id }) => {
  //  --- variables ---
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)

  const { data, isLoading, refetch } = useQuery(
    ['object-vipps-creds', id],
    () => ApiHelper.showObjectVippsBlob(id),
    {
      refetchOnWindowFocus: false,
    }
  )

  //  --- Functionality ---
  const saveEditedObject = async (values) => {
    await toast.promise(ApiHelper.saveObjectVippsBlob(id, values), {
      loading: 'Updating Vipps credentials...',
      success: 'Vipps credentials saved',
      error: 'Could not save Vipps',
    })
    if (refetch) {
      await refetch()
    }
    setEditing(false)
  }

  if (!data || isLoading) {
    return <Spinner />
  }

  return (
    <Formik
      initialValues={data}
      validateOnChange
      validationSchema={validation}
      onSubmit={saveEditedObject}
    >
      {({ isValid, isSubmitting, submitForm, resetForm }) => (
        <div className="flex-wrap space-y-2 overflow-auto">
          <div className="flex flex-row justify-between space-x-4 border-b border-gray-200 py-1">
            <span className=" font-bold">Vipps Credentials</span>
            {!editing ? (
              <Button primary onClick={(e) => setEditing(true)}>
                {t('application:edit')}
              </Button>
            ) : (
              <span className="flex flex-row space-x-2">
                <Button
                  className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
                  disabled={isSubmitting}
                  onClick={() => {
                    resetForm()
                    setEditing(false)
                  }}
                >
                  {t('application:cancel')}
                </Button>
                <Button
                  disabled={!isValid || isSubmitting}
                  className="bg-green-400 text-white hover:bg-green-500"
                  onClick={submitForm}
                >
                  {t('application:submit')}
                </Button>
              </span>
            )}
          </div>
          <FormBuilder disabled={!editing} schema={vippsSchema} />
        </div>
      )}
    </Formik>
  )
}

export default VippsCredentials

const validation = yup.object({
  production: yup.boolean().required(),
  msn: yup.string().strip().nullable(),
  subkey: yup.string().strip().nullable(),
  clientId: yup.string().strip().nullable(),
  clientSecret: yup.string().strip().nullable(),
  transactionText: yup.string().strip().nullable(),
})

const vippsSchema = [
  {
    label: 'Production',
    name: 'production',
    type: 'checkbox',
    className: 'max-h-5 my-auto',
  },
  {
    label: 'MSN',
    name: 'msn',
  },
  {
    label: 'Subscription Key',
    name: 'subkey',
  },
  {
    label: 'Client ID',
    name: 'clientId',
  },
  {
    label: 'Client Secret',
    name: 'clientSecret',
  },
  {
    label: 'Transaction Text',
    name: 'transactionText',
  },
]

import React from 'react'
import { Button, SelectInput, TimeInput } from 'components'
import { useTranslation } from 'react-i18next'

const ObjectScheduleTask = ({
  commandMap,
  index,
  onChange,
  isValid,
  onDestroy,
  command,
  commandParam,
  time,
}) => {
  //  --- variables ---
  const { t } = useTranslation()

  //  --- functionality ---
  const getComandByNative = (native) => {
    return commandMap.find((c) => c.native === native)
  }

  const onCommandChange = (e) => {
    onChange(index, {
      command: getComandByNative(e.target.value),
      time,
      commandParam,
    })
  }

  const onParamChange = (e) => {
    onChange(index, { time, command, commandParam: e.target.value })
  }

  const onTimeChange = (newTime) => {
    onChange(index, { time: newTime, command, commandParam })
  }

  //  --- response ---
  return (
    <div className="flex flex-row space-x-3 border-b-2 py-1">
      <SelectInput
        className="w-56"
        options={commandMap.map((command) => ({
          value: command.native,
          label: command.native,
        }))}
        onChange={onCommandChange}
      />
      <input
        placeholder="Param"
        onChange={onParamChange}
        className="w-36 rounded border-2 border-gray-300 px-2 py-2"
      />
      <TimeInput
        isInvalid={!isValid}
        className="w-36"
        onChange={onTimeChange}
      />
      <Button className="mx-auto" onClick={() => onDestroy(index)}>
        {t('application:delete')}
      </Button>
    </div>
  )
}

export { ObjectScheduleTask }

import ApiHelper from 'helpers/ApiHelper'
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import {
  ObjectName,
  ObjectOnlineState,
  ObjectStatusText,
  IndeterminateCheckbox,
} from 'components'
import { ObjectActive, ObjectPublic } from 'components/shared/Objects'

/**
 * This helps us to group children under it's parent object if exists in list. Then we can have expanding in the table
 */
const sortObjectsWithChildren = (objects) => {
  let objectMap = {}
  for (let k = 0; k < objects.length; k++) {
    objectMap[objects[k].objectId] = {
      ...objects[k],
      overrideOnClick: [0],
    }
  }

  for (let i = 0; i < objects.length; i++) {
    if (objects[i].parentId != null) {
      if (objectMap[objects[i].parentId]) {
        // list has parent
        objectMap[objects[i].parentId].subRows =
          objectMap[objects[i].parentId].subRows || []

        objectMap[objects[i].parentId].subRows.push({
          ...objects[i],
          isChild: true,
        })
        delete objectMap[objects[i].objectId]
      }
    }
  }
  return [...Object.values(objectMap)]
}

const sortObjectsWithChildrenSelect = (objects) => {
  let objectMap = {}
  for (let k = 0; k < objects.length; k++) {
    objectMap[objects[k].objectId] = {
      ...objects[k],
      overrideOnClick: [0, 1],
    }
  }

  for (let i = 0; i < objects.length; i++) {
    if (objects[i].parentId != null) {
      if (objectMap[objects[i].parentId]) {
        // list has parent
        objectMap[objects[i].parentId].subRows =
          objectMap[objects[i].parentId].subRows || []

        objectMap[objects[i].parentId].subRows.push({
          ...objects[i],
          isChild: true,
        })
        delete objectMap[objects[i].objectId]
      }
    }
  }
  return [...Object.values(objectMap)]
}

const AdminTabs = (accessLevel, tabUtils) => {
  const LogTab = {
    i18n: 'showObject:tabs.logs',
    component: 'ObjectLogsTable',
    key: 'logs_table',
    getProps: (obj) => ({
      downloadUrls: {
        json: `/api/objects/${obj.objectId}/Logs`,
      },
    }),
  }

  if (accessLevel !== 'delegate') {
    return [LogTab]
  }

  let tabs = [
    LogTab,
    {
      i18n: 'showObject:tabs.status',
      component: 'ObjectStatusTable',
      key: 'status_table',
    },
    {
      i18n: 'showObject:tabs.administrators',
      component: 'ObjectAdministratorTable',
      key: 'administrators_table',
    },
    {
      i18n: 'showObject:tabs.userAccess',
      component: 'ObjectUserAccessTable',
      key: 'users_table',
      preload: true,
    },
    {
      i18n: 'showObject:tabs.groupAccess',
      component: 'ObjectGroupTable',
      key: 'group_table',
      preload: true,
    },
    {
      i18n: 'showObject:tabs.tenantAccess',
      component: 'ObjectTenantAccessTable',
      key: 'tenants_table',
      preload: true,
    },
    {
      i18n: 'showObject:tabs.children',
      component: 'ObjectChildrenTable',
      key: 'children_table',
    },
    {
      i18n: 'showObject:tabs.objectRelations',
      component: 'ObjectRelationsTable',
      key: 'object_relations',
    },
    !tabUtils.isChildObject && {
      i18n: 'showObject:tabs.schedule',
      component: 'ObjectScheduleTable',
      key: 'schedules_table',
      getProps: (object) => ({
        accessLevel: 'delegate',
        object,
      }),
    },
    {
      i18n: 'GeoFence',
      component: 'GeoFence',
      key: 'geofence',
    },
    {
      i18n: 'Info',
      component: 'EditObjectInformation',
      key: 'info',
    },
    {
      i18n: 'showObject:tabs.messaging',
      component: 'MessagingTab',
      key: 'messaging_tab',
      getProps: (obj) => ({
        object: obj,
      }),
    },
    {
      i18n: 'showObject:tabs.payments',
      component: 'PaymentsTab',
      key: 'payment_tab',
      getProps: (obj) => {
        return {
          object: {
            ...obj,
            vipps: JSON.stringify(obj.vippsCredentials, null, 4),
          },
          onSave: (newObj) => {
            let clone = { ...newObj }
            clone.vippsCredentials = JSON.parse(newObj.vipps)
            return ApiHelper.updateObject(obj.objectId, clone)
          },
          tabUtils,
        }
      },
    },
    {
      i18n: 'showObject:tabs.subscription',
      component: 'SubscriptionTab',
      key: 'subscription_tab',
      getProps: (obj) => ({
        object: obj,
      }),
    },
    {
      // i18n: 'showObject:tabs.messaging',
      component: 'ExternalAuthTab',
      title: 'External Auth',
      key: 'ext_auth_tab',
    },
    {
      i18n: 'showObject:tabs.labels',
      component: 'ObjectLabelsForm',
      key: 'labels_editor',
    },
  ]

  return tabs.filter(Boolean)
}

const tableHeaders = {
  delegate: [
    {
      id: 'select',
      resizable: false,
      width: 40,
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div className="px-1">
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: (props) => {
        return (
          <div className="px-1">
            <IndeterminateCheckbox {...props.row.getToggleRowSelectedProps()} />
          </div>
        )
      },
    },
    // {
    //   Header: 'ID',
    //   i18n: 'indexObjects:indexObjectsTable.id',
    //   accessor: 'objectId',
    //   resizable: false,
    //   minWidth: 50,
    //   maxWidth: 50,
    // },
    {
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <div
          className="flex h-full cursor-pointer flex-col justify-center"
          {...getToggleAllRowsExpandedProps()}
        >
          {isAllRowsExpanded ? (
            <ChevronDownIcon className="h-6 w-6" />
          ) : (
            <ChevronRightIcon className="h-6 w-6" />
          )}
        </div>
      ),
      disableSortBy: true,
      accessor: 'children',
      resizable: false,
      minWidth: 40,
      maxWidth: 40,
      Cell: (props) => {
        return (
          <div className="flex h-full flex-col justify-center">
            {props.row.original.subRows && (
              <span {...props.row.getToggleRowExpandedProps({})}>
                {props.row.isExpanded ? (
                  <ChevronDownIcon className="h-6 w-6" />
                ) : (
                  <ChevronRightIcon className="h-6 w-6" />
                )}
              </span>
            )}
          </div>
        )
      },
    },
    {
      Header: 'Name',
      i18n: 'indexObjects:indexObjectsTable.name',
      accessor: 'name',
      width: 300,
      Cell: (props) => <ObjectName object={props.row.original} />,
    },
    {
      Header: 'Tenant',
      i18n: 'indexObjects:indexAdminObjectsTable.tenant',
      accessor: 'tenantName',
      width: 200,
    },
    {
      Header: 'External ID',
      i18n: 'indexObjects:indexObjectsTable.externalId',
      accessor: 'externalId',
      width: 150,
    },
    {
      Header: 'Active',
      accessor: 'active',
      width: 85,
      Cell: (props) => {
        return <ObjectActive object={props.row.original} />
      },
    },
    {
      Header: 'Public',
      accessor: 'isPublic',
      width: 85,
      Cell: (props) => {
        return <ObjectPublic object={props.row.original} />
      },
    },
    {
      Header: 'State',
      i18n: 'indexObjects:indexObjectsTable.state',
      accessor: 'state',
      width: 85,
      Cell: (props) => {
        return <ObjectOnlineState object={props.row.original} />
      },
    },
    // {
    //   Header: 'Delegation',
    //   i18n: 'indexObjects:indexAdminObjectsTable.delegation',
    //   accessor: 'delegationLevel',
    //   width: 200,
    // },
    {
      Header: 'Status',
      id: 'status',
      accessor: 'statusText',
      width: 220,
      Cell: ({ row }) => {
        return <ObjectStatusText object={row.original} />
      },
    },
  ],
  access: [
    // {
    //   Header: 'ID',
    //   i18n: 'indexObjects:indexObjectsTable.id',
    //   accessor: 'objectId',
    //   resizable: false,
    //   width: 30,
    //   minWidth: 30,
    //   maxWidth: 30,
    // },
    {
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <div
          className="flex h-full cursor-pointer flex-col justify-center"
          {...getToggleAllRowsExpandedProps()}
        >
          {isAllRowsExpanded ? (
            <ChevronDownIcon className="h-6 w-6" />
          ) : (
            <ChevronRightIcon className="h-6 w-6" />
          )}
        </div>
      ),
      disableSortBy: true,
      accessor: 'children',
      resizable: false,
      width: 30,
      minWidth: 30,
      maxWidth: 30,
      Cell: (props) => {
        return (
          <div className="flex h-full flex-col justify-center">
            {props.row.original.subRows && (
              <span {...props.row.getToggleRowExpandedProps({})}>
                {props.row.isExpanded ? (
                  <ChevronDownIcon className="h-6 w-6" />
                ) : (
                  <ChevronRightIcon className="h-6 w-6" />
                )}
              </span>
            )}
          </div>
        )
      },
    },
    {
      Header: 'Name',
      i18n: 'indexObjects:indexObjectsTable.name',
      accessor: 'name',
      width: 300,
      Cell: (props) => {
        return <ObjectName object={props.row.original} />
      },
    },
    {
      Header: 'External ID',
      i18n: 'indexObjects:indexObjectsTable.externalId',
      accessor: 'externalId',
      width: 150,
    },
    {
      Header: 'Public',
      accessor: 'isPublic',
      width: 85,
      Cell: (props) => {
        return <ObjectPublic object={props.row.original} />
      },
    },
    {
      Header: 'State',
      i18n: 'indexObjects:indexObjectsTable.state',
      accessor: 'state',
      width: 85,
      Cell: ({ row }) => {
        return <ObjectOnlineState object={row.original} />
      },
    },
    {
      Header: 'Status',
      id: 'status',
      width: 220,
      Cell: ({ row }) => {
        return <ObjectStatusText object={row.original} />
      },
    },
  ],
}

export {
  sortObjectsWithChildren,
  sortObjectsWithChildrenSelect,
  AdminTabs,
  tableHeaders,
}

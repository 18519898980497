import React from 'react'
import cn from 'classnames'

const SelectInput = ({ options, className, ...rest }) => {
  return (
    <select
      {...rest}
      className={cn('rounded border-2 border-gray-300 px-2 py-2', className)}
    >
      {options.map((option, index) => {
        return (
          <option key={`select_option_${index}`} value={option.value}>
            {option.label}
          </option>
        )
      })}
    </select>
  )
}

export default SelectInput

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, FilteredTable } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import AdminTenantCreateModal from './AdminTenantCreateModal'
import UserTypeIcon from 'components/shared/Users/UserTypeIcon'

const AdminTenantTenantsIndexView = () => {
  //  --- Variables ---
  const { t } = useTranslation()
  const [createOpen, setCreateOpen] = useState(false)

  //  --- Response ---
  return (
    <Card cardType="primary-content" className="mb-4 h-full">
      <FilteredTable
        apiName="indexTenantTenants"
        dataApi={() => ApiHelper.indexTenantTenants()}
        filterCacheKey="indexTenantTenants"
        initialSort={{ id: 'name', desc: false }}
        placeholder={t('application:search')}
        filterDebounce={true}
        removeFooter={true}
        scrollRestore={true}
        // rowOnClick={(row) => history.push(`/access/users/${row.userId}`)}
        tableHeaders={tableHeaders}
      >
        <Button onClick={(e) => setCreateOpen(true)}>Add Tenant</Button>
      </FilteredTable>
      {createOpen && (
        <AdminTenantCreateModal
          visible={true}
          toggle={(e) => setCreateOpen(false)}
        />
      )}
    </Card>
  )
}

const tableHeaders = [
  // {
  //   Header: 'ID',
  //   i18n: 'indexObjects:objectUsersTable.id',
  //   accessor: 'tenantId',
  //   width: 70,
  // },
  {
    Header: 'Display Name',
    i18n: 'indexObjects:objectUsersTable.displayName',
    accessor: 'name',
    width: 600,
  },
  {
    Header: 'Type',
    i18n: 'indexObjects:objectUsersTable.type',
    accessor: 'userTypeId',
    width: 50,
    Cell: (props) => <UserTypeIcon userTypeId={1} />,
  },
]

export default AdminTenantTenantsIndexView

import {
  ChevronLeftIcon,
  CubeIcon,
  CheckIcon,
  CheckCircleIcon,
  GlobeAmericasIcon,
  GlobeAltIcon,
  MapPinIcon,
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  BarsArrowUpIcon,
  BarsArrowDownIcon,
  UserIcon,
  UsersIcon,
  WifiIcon,
  ClockIcon,
  EyeIcon,
  HeartIcon,
  StarIcon,
  MagnifyingGlassIcon,
  NoSymbolIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid'

const Icon = ({
  iconName,
  className,
  color = 'text-gray-500',
  size = 5,
  ...props
}) => {
  const getIcon = () => {
    switch (iconName) {
      case 'search':
        return (
          <MagnifyingGlassIcon
            className={`w-${size} h-${size} ${color} mx-1`}
          />
        )
      case 'heart':
        return <HeartIcon className={`w-${size} h-${size} ${color} mx-1`} />
      case 'star':
        return <StarIcon className={`w-${size} h-${size} ${color} mx-1`} />
      case 'clock':
        return <ClockIcon className={`w-${size} h-${size} ${color} mx-1`} />
      case 'check':
        return <CheckIcon className={`w-${size} h-${size} ${color} mx-1`} />
      case 'check-circle':
        return (
          <CheckCircleIcon className={`w-${size} h-${size} ${color} mx-1`} />
        )
      case 'chev-left':
        return (
          <ChevronLeftIcon className={`w-${size} h-${size} ${color} mx-1`} />
        )
      case 'cube':
        return <CubeIcon className={`w-${size} h-${size} ${color} mx-1`} />
      case 'eye':
        return <EyeIcon className={`w-${size} h-${size} ${color} mx-1`} />
      case 'globe':
        return (
          <GlobeAmericasIcon className={`w-${size} h-${size} ${color} mx-1`} />
        )
      case 'no-symbol':
        return <NoSymbolIcon className={`w-${size} h-${size} ${color} mx-1`} />
      case 'question':
        return (
          <QuestionMarkCircleIcon
            className={`w-${size} h-${size} ${color} mx-1`}
          />
        )
      case 'globeAlt':
        return (
          <GlobeAltIcon className={`w-${size} h-${size} mr-2 ${color} mx-1`} />
        )
      case 'login':
        return (
          <ArrowLeftOnRectangleIcon
            className={`w-${size} h-${size} ${color} mx-1`}
          />
        )
      case 'logout':
        return (
          <ArrowRightOnRectangleIcon
            className={`w-${size} h-${size} ${color} mx-1`}
          />
        )
      case 'user':
        return <UserIcon className={`w-${size} h-${size} ${color} mx-1`} />
      case 'menu':
        return <Bars3Icon className={`w-${size} h-${size} ${color} mx-1`} />
      case 'users':
        return <UsersIcon className={`w-${size} h-${size} ${color} mx-1`} />
      case 'locationMarker':
        return <MapPinIcon className={`w-${size} h-${size} ${color} mx-1`} />
      case 'sort-asc':
        return (
          <BarsArrowUpIcon
            className={`w-${size} h-${size} mt-1 ${color} mx-1`}
          />
        )
      case 'sort-desc':
        return (
          <BarsArrowDownIcon
            className={`w-${size} h-${size} mt-1 ${color} mx-1`}
          />
        )
      case 'wifi':
        return <WifiIcon className={`w-${size} h-${size} ${color} mx-1`} />
      default:
        return ''
    }
  }

  return (
    <div className={`${className} flex flex-col justify-center`} {...props}>
      {getIcon()}
    </div>
  )
}

export default Icon

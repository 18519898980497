import React from 'react'
import { Icon } from '../'

const ObjectPublic = ({ object }) => {
  return (
    <Icon
      iconName="eye"
      color={object.isPublic ? 'text-green-400' : 'text-red-400'}
      className="mx-3"
    />
  )
}

export default ObjectPublic

import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useQuery } from '@tanstack/react-query'
import { Card, Spinner } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { differenceInSeconds, format } from 'date-fns'

function formatLiveDate(dateString) {
  const diffInSeconds = differenceInSeconds(new Date(), new Date(dateString))

  let secNum = parseInt(diffInSeconds, 10) // don't forget the second param
  let hours = Math.floor(secNum / 3600)
  let minutes = Math.floor((secNum - hours * 3600) / 60)
  let seconds = secNum - hours * 3600 - minutes * 60

  if (hours < 10) hours = '0' + hours
  if (minutes < 10) minutes = '0' + minutes
  if (seconds < 10) seconds = '0' + seconds

  return hours + ':' + minutes + ':' + seconds
}

const LiveDate = ({ date }) => {
  const [val, setVal] = useState(formatLiveDate(date))

  useEffect(() => {
    let inter = setInterval(() => {
      setVal(formatLiveDate(date))
    }, 1000)

    return () => clearInterval(inter)
  }, [setVal, date])

  return val
}

const AdminSettingsView = () => {
  //  --- Variables ---
  const { data: payloadData, isLoading: isPayloadLoading } = useQuery(
    ['showUserPayload'],
    () => ApiHelper.showUserPayload(),
    {
      refetchOnWindowFocus: false,
    }
  )

  const { data: userData, isLoading: isUserDataLoading } = useQuery(
    ['showMe'],
    () => ApiHelper.showMe(),
    {
      refetchOnWindowFocus: false,
    }
  )

  const { data: officeSystemData, isLoading: is365ApiLoading } = useQuery(
    ['show365ApiVersion'],
    () => ApiHelper.show365ApiVersion(),
    {
      refetchOnWindowFocus: false,
    }
  )

  const { data: solverSystemData, isLoading: isSolverApiLoading } = useQuery(
    ['showSolverApiVersion'],
    () => ApiHelper.showSolverApiVersion(),
    {
      refetchOnWindowFocus: false,
    }
  )

  const copyToClipboard = () => {
    navigator.clipboard.writeText(payloadData.access_token)
    toast.success('Copied to clipboard')
  }

  const leftColW = 'w-48'

  if (payloadData && userData && !isPayloadLoading && !isUserDataLoading) {
    return (
      <Card>
        <div className="flex flex-col space-y-3">
          <div className="flex">
            <p className={`${leftColW} font-semibold`}>Auth Authority:</p>
            <p>
              {payloadData.provider === 'microsoft' ? 'Microsoft' : 'Vipps'}
            </p>
          </div>
          <div className="flex">
            <p className={`${leftColW} font-semibold`}>Name:</p>
            <p>{userData.displayName}</p>
          </div>
          <div className="flex">
            <p className={`${leftColW} font-semibold`}>Username:</p>
            <p>{userData.userName}</p>
          </div>
          <div className="flex">
            <p className={`${leftColW} font-semibold`}>Email:</p>
            <p>{userData.eMail}</p>
          </div>
          <div className="flex">
            <p className={`${leftColW} font-semibold`}>Mobile:</p>
            <p>{userData.mobilePhone}</p>
          </div>
          <div className="flex">
            <p className={`${leftColW} font-semibold`}>Tenant:</p>
            <p>{userData.tenantName}</p>
          </div>
          <div className="flex">
            <p className={`${leftColW} font-semibold`}>OID:</p>
            <p>{payloadData.oid}</p>
          </div>
          <div className="flex">
            <p className={`${leftColW} font-semibold`}>Session Time:</p>
            <p>
              <LiveDate date={payloadData.createdDate} />
            </p>
          </div>
          <div className="flex">
            <p className={`${leftColW} font-semibold`}>Expiry Date:</p>
            <p>
              {payloadData.expiryDate
                ? format(new Date(payloadData.expiryDate), 'yyyy-MM-dd')
                : '-'}
            </p>
          </div>
          <div className="flex">
            <p className={`${leftColW} font-semibold`}>Token:</p>
            <code
              style={{ overflowWrap: 'anywhere', cursor: 'copy' }}
              onClick={copyToClipboard}
              className="w-full select-none rounded border bg-gray-100 p-2 text-sm hover:bg-gray-200"
            >
              {payloadData.access_token}
            </code>
          </div>
          <div className="flex items-baseline">
            <p className={`${leftColW} font-semibold`}>O365 API:</p>
            {officeSystemData && !is365ApiLoading && (
              <code className="pr-3">
                v{officeSystemData.major}.{officeSystemData.minor}.
                {officeSystemData.build}.{officeSystemData.revision}
              </code>
            )}
            <a href={payloadData.o365Api} target="_blank" rel="noreferrer">
              {payloadData.o365Api}
            </a>
          </div>
          <div className="flex">
            <p className={`${leftColW} font-semibold`}>Solver API:</p>
            {solverSystemData && !isSolverApiLoading && (
              <code className="pr-3">
                v{solverSystemData.major}.{solverSystemData.minor}.
                {solverSystemData.build}.{solverSystemData.revision}
              </code>
            )}
            <a href={payloadData.solverApi} target="_blank" rel="noreferrer">
              {payloadData.solverApi}
            </a>
          </div>
        </div>
      </Card>
    )
  }
  return (
    <Card>
      <div className="flex">
        <Spinner className="mx-auto" />
      </div>
    </Card>
  )
}

export default AdminSettingsView

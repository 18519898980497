import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FlexibleModal, TextInput } from 'components'
import AsyncSelect from 'react-select/async'
import ApiHelper from 'helpers/ApiHelper'
import toast from 'react-hot-toast'
import { validateDomain } from 'helpers/ApplicationHelper'

const CreateChildObjectModal = ({ id, refetch }) => {
  //  --- variables ---
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [user, setUser] = useState('')
  const [tenant, setTenant] = useState('')
  const [visible, setVisible] = useState(false)

  const onCreateObject = async () => {
    await toast.promise(
      ApiHelper.createChildObject(id, {
        newName: tenant.tenantId > 0 ? name : '',
        tenant: tenant,
        newOwnerId: user.userId,
      }),
      {
        loading: 'Creating child object.',
        success: 'Child object created.',
        error: 'Child object could not be created.',
      }
    )
    setVisible(false)
    if (refetch) {
      refetch()
    }
  }

  const searchTenants = async (query) => {
    const results = await ApiHelper.searchTenants(query)

    const validated = validateDomain(query)
    if (results.length < 1 && validated) {
      return [
        {
          name: `Create new - `,
          primaryDomain: validated,
          tenantId: 0,
          adminId: null,
        },
      ]
    } else {
      return results
    }
  }

  const isDisabled = () => {
    return !(name && tenant)
  }

  return (
    <div>
      <Button onClick={() => setVisible(true)}>Create</Button>
      <FlexibleModal size="modal-md" visible={visible}>
        <div className="space-y-4">
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row justify-between space-x-4">
              <p className="my-auto">Name</p>
              <TextInput
                className="w-3/4"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="flex flex-row justify-between space-x-4">
              <p className="my-auto">Tenant:</p>
              <AsyncSelect
                className="w-3/4"
                cacheOptions
                defaultOptions
                loadOptions={searchTenants}
                formatOptionLabel={(option) =>
                  `${option.name} (${option.primaryDomain})`
                }
                onChange={setTenant}
                placeholder="Search Tenants..."
                styles={{ borderRadius: 0.5 }}
              />
            </div>
            <div className="flex flex-row justify-between space-x-4">
              <p className="my-auto">Owner:</p>
              <AsyncSelect
                className="w-3/4"
                cacheOptions
                defaultOptions
                isClearable
                loadOptions={ApiHelper.searchUsers}
                formatOptionLabel={(option) =>
                  `${option.userName} (${option.displayName})`
                }
                onChange={setUser}
                placeholder="Search Users..."
              />
            </div>
          </div>
          <div className="flex flex-row justify-end space-x-4">
            <Button
              className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
              onClick={() => setVisible(false)}
            >
              {t('application:cancel')}
            </Button>
            <Button
              disabled={isDisabled()}
              className="bg-green-400 text-white hover:bg-green-500"
              onClick={onCreateObject}
            >
              {t('application:submit')}
            </Button>
          </div>
        </div>
      </FlexibleModal>
    </div>
  )
}
export default CreateChildObjectModal

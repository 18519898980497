import React from 'react'

import './Modal.css'

const Modal = ({ visible, size = 'min-w-full', ...props }) => {
  return (
    <div
      className={`z-50 h-screen w-screen overflow-auto md:px-20 md:py-10 ${
        visible ? 'absolute' : 'hidden'
      } top-0 left-0 flex flex-col justify-center bg-white bg-opacity-80`}
    >
      <div className="flex h-full flex-row justify-center">
        <div
          className={`md:rounded-xl ${size} h-full max-w-screen-2xl border-2 bg-white md:shadow-xl`}
        >
          <div className="h-full p-8 md:px-16 md:py-12">
            {visible ? props.children : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal

import React from 'react'

const Tag = ({ property, value }) => (
  <div className="mt-2 mr-2 flex items-center justify-center rounded-full bg-gray-100 px-4 py-1 text-sm md:mt-4 md:mr-4">
    {property && (
      <>
        <span className="">{property}</span>
        <span className="mr-2">:</span>
      </>
    )}
    <span
      className="overflow-ellipsis whitespace-nowrap font-semibold"
      title={value}
    >
      {value}
    </span>
  </div>
)

export default Tag

import { useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ApiHelper from 'helpers/ApiHelper'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { XIcon } from '@heroicons/react-v1/solid'

import { Button, TextInput } from 'components'

export default function ObjectLabelsEditor({ id: objectId }) {
  const queryKey = ['object-labels', objectId]

  const [editingLabel, setEditingLabel] = useState('')
  const [labelError, setLabelError] = useState()
  const { data, isLoading, isError } = useQuery(queryKey, () =>
    ApiHelper.showObjectLabels(objectId)
  )
  const queryClient = useQueryClient()
  const inputRef = useRef()

  const addLabelMutation = useMutation(
    (newLabel) => {
      return ApiHelper.updateObjectLabels(objectId, [...data, newLabel])
    },
    {
      onMutate: async (newLabel) => {
        // Cancel outgoing refetches
        await queryClient.cancelQueries(queryKey)

        const stale = queryClient.getQueryData(queryKey)

        queryClient.setQueryData(queryKey, (labels) => [...labels, newLabel])

        return { stale }
      },
      onError: (err, newLabel, context) => {
        // On error, reset the stale Labels
        queryClient.setQueryData(queryKey, context.stale)
      },
      // Refetch the server data after the mutation
      onSettled: () => {
        queryClient.invalidateQueries(queryKey)
      },
    }
  )

  const deleteLabelsMutation = useMutation(
    () => {
      return ApiHelper.deleteObjectLabels(objectId)
    },
    {
      onMutate: async () => {
        // Cancel outgoing refetches
        await queryClient.cancelQueries(queryKey)

        const stale = queryClient.getQueryData(queryKey)

        queryClient.setQueryData(queryKey, () => [])

        return { stale }
      },
      onError: (err, newLabel, context) => {
        // On error, reset the stale Labels
        queryClient.setQueryData(queryKey, context.stale)
      },
      // Refetch the server data after the mutation
      onSettled: () => {
        queryClient.invalidateQueries(queryKey)
      },
    }
  )

  const removeLabelMutation = useMutation(
    (targetLabel) => {
      const cached = queryClient.getQueryData(queryKey)
      const newLabels = dropFromList(cached, targetLabel)

      return ApiHelper.updateObjectLabels(objectId, newLabels)
    },
    {
      onMutate: async (targetLabel) => {
        // Cancel outgoing refetches
        await queryClient.cancelQueries(queryKey)

        const stale = queryClient.getQueryData(queryKey)

        queryClient.setQueryData(queryKey, (labels) =>
          dropFromList(labels, targetLabel)
        )

        return { stale }
      },
      onError: (err, newLabel, context) => {
        // On error, reset the stale Labels
        queryClient.setQueryData(queryKey, context.stale)
      },
      // Refetch the server data after the mutation
      onSettled: () => {
        queryClient.invalidateQueries(queryKey)
      },
    }
  )

  const addLabel = () => {
    // Clear errors
    setLabelError()

    // Validation
    if (editingLabel === '') return
    if (data.includes(editingLabel)) {
      setLabelError('Label already exists')
      return
    }

    // Update the server
    addLabelMutation.mutate(editingLabel)

    // Reset the input field & refocus
    setEditingLabel('')
    inputRef.current.focus()
  }

  if (isLoading) return <p>Loading labels</p>
  if (isError) return <p>Error loading lables</p>

  return (
    <div className="flex flex-col items-start space-y-3">
      <form
        className="flex w-full flex-col space-y-2"
        onSubmit={(e) => {
          e.preventDefault()
          addLabel()
        }}
      >
        <div className="flex flex-row space-x-2">
          <TextInput
            ref={inputRef}
            className="w-full"
            placeholder="Enter label"
            type="text"
            name="label"
            id="label"
            value={editingLabel}
            onChange={(e) => setEditingLabel(e.target.value)}
          />

          <Button type="submit" className="whitespace-nowrap">
            {addLabelMutation.isLoading ? 'Adding label...' : 'Add label'}
          </Button>
        </div>
        {labelError && (
          <span className="text-xs font-medium text-red-700">{labelError}</span>
        )}
      </form>

      <ul className="flex w-full flex-wrap border-t border-gray-200 pt-3">
        {data.map((label) => (
          <li className="pr-2 pt-1" key={label}>
            <span className="inline-flex items-center rounded-md bg-gray-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-800">
              {label}
              <button
                onClick={() => removeLabelMutation.mutate(label)}
                type="button"
                className="focus:outline-none ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white"
              >
                <span className="sr-only">Remove large option</span>
                <XIcon className="h-3 w-3 text-current" />
              </button>
            </span>
          </li>
        ))}
      </ul>

      {data.length === 0 ? (
        <span className="text-sm font-medium text-gray-600">
          No labels available
        </span>
      ) : (
        <div className="flex flex-wrap items-center space-x-1 pt-4">
          <ExclamationCircleIcon className="h-3 w-3 text-red-700" />
          <button
            onClick={deleteLabelsMutation.mutate}
            className="text-xs font-medium italic text-red-700 hover:text-red-800 hover:underline"
          >
            {deleteLabelsMutation.isLoading
              ? 'Removing labels...'
              : 'Remove all labels'}
          </button>
        </div>
      )}
    </div>
  )
}

const dropFromList = (list, target) => {
  return list.filter((l) => l !== target)
}

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useCommandMap = (object, accessLevel) => {
  const { i18n } = useTranslation()

  const sortCommandMapByLanguage = useCallback(
    (map) => {
      for (let i = 0; i < map.length; i++) {
        if (map[i].language1.toLowerCase().includes(i18n.language))
          return map[i].commands
      }

      if (map.length === 0) {
        return []
      }

      return map[0].commands
    },
    [i18n.language]
  )

  return useMemo(() => {
    switch (accessLevel) {
      case 'access':
        return sortCommandMapByLanguage(
          object?.commandMap ? object.commandMap.userCommands : []
        )

      case 'delegate':
        return sortCommandMapByLanguage(
          object?.commandMap ? object.commandMap.adminCommands : []
        )

      default:
        // return object.commands.map((c) => ({ display: c, native: c }))
        return []
    }
  }, [
    accessLevel,
    // object.commands,
    sortCommandMapByLanguage,
    object.commandMap,
  ])
}

import React from 'react'

const SmallButton = ({ children, className, onClick, ...props }) => {
  return (
    <button
      className={`focus:outline-none my-auto h-7 rounded-md border bg-gray-100 px-2 hover:bg-gray-300 ${
        className === undefined ? '' : className
      }`}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}

export default SmallButton

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isAuthenticated } from 'helpers/ApplicationHelper'

const GuardedRoute = ({ auth, ...props }) => {
  if (!isAuthenticated()) return <Redirect to="/login" />
  return <Route {...props} />
}

export default GuardedRoute

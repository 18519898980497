import React from 'react'

import './Modal.css'

const FlexibleModal = ({ children, size, visible }) => {
  return (
    visible && (
      <div>
        <div className="absolute top-0 left-0 z-10 h-full w-full bg-white opacity-80" />
        <div
          className={`modal flex border-2 bg-white ${size} w-full opacity-100 md:rounded-xl md:shadow-xl`}
        >
          <div className="w-full p-8">{children}</div>
        </div>
      </div>
    )
  )
}

export default FlexibleModal

import React, { useState } from 'react'
import Map from './Map'
import Search from './Search'

const MapPicker = (props) => {
  const [selectedLocation, setSelectedLocation] = useState()

  const chooseLocation = (location) => {
    setSelectedLocation(location)
    if (props.onLocationChange) {
      props.onLocationChange(location)
    }
  }

  return (
    <div className="flex h-full flex-col overflow-hidden rounded-lg md:flex-row ">
      <div className="">
        <Search onSelect={chooseLocation} />
      </div>
      <div className="hidden h-full w-full md:block">
        <Map
          address={selectedLocation ? selectedLocation.address : null}
          center={selectedLocation ? selectedLocation.coordinates : null}
        />
      </div>
    </div>
  )
}

export default MapPicker

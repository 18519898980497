import { useTranslation } from 'react-i18next'
import { Card, MenuButton, ObjectNameInput } from 'components'
import CommandDropdown from './CommandDropdown'
import ObjectCommands from './ObjectCommands'
import ObjectIsOnline from './ObjectIsOnline'
import ObjectTags from './ObjectTags'
import toast from 'react-hot-toast'
import ApiHelper from 'helpers/ApiHelper'
import ObjectIsPublic from './ObjectIsPublic'
import ObjectIsActive from './ObjectIsActive'
import { FavouriteControl } from './FavouriteControl'
import { useCommandMap } from 'hooks/useCommandMap'
import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from 'helpers/QueryHelper'
import { sortStatusTextByLanguage } from 'helpers/StatusTextHelper'

export default function ObjectDetailsCard({
  accessLevel,
  object,
  refetchRef,
  externalAuthData,
  ...props
}) {
  const { i18n } = useTranslation()
  const favouritesQueryKey = ['favourite', object.objectId]

  const { data: isFavourite, isLoading: isFavouriteLoading } = useQuery(
    favouritesQueryKey,
    () => ApiHelper.isObjectFavourited(object.objectId)
  )

  const openQRFromCommand = (command) => {
    window.open(`/objects/${object.objectId}/qr/${command}`, '_blank')
  }

  const favouriteMutation = useMutation({
    mutationFn: () => {
      if (isFavourite) {
        return ApiHelper.unfavouriteObject(object.objectId)
      } else {
        return ApiHelper.favouriteObject(object.objectId)
      }
    },
    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: favouritesQueryKey })
      // Snapshot the previous value
      const previousState = queryClient.getQueryData(favouritesQueryKey)
      // Optimistically update to the new value
      queryClient.setQueryData(favouritesQueryKey, (oldState) => !oldState)
      // Return a context object with the snapshotted value
      return { previousState }
    },
    onError: (_err, _newState, context) => {
      queryClient.setQueryData(favouritesQueryKey, context.previousState)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: favouritesQueryKey })
    },
  })

  const commandMap = useCommandMap(object, accessLevel)

  const onTogglePublic = async () => {
    const newVal = !object.isPublic
    await toast.promise(
      ApiHelper.updateObject(object.objectId, { ...object, isPublic: newVal }),
      {
        success: newVal ? 'Object is now public' : 'Object is now private',
        loading: `Setting public to ${newVal}`,
        error: `Could not change object public to ${newVal}`,
      }
    )

    if (refetchRef) {
      refetchRef()
    }
  }

  const onToggleActive = async () => {
    const newVal = !object.active
    await toast.promise(ApiHelper.setObjectActive(object.objectId, newVal), {
      success: newVal ? 'Object is now active' : 'Object is now deactivated',
      loading: `Setting object active to ${newVal}`,
      error: `Could not change object active state to ${newVal}`,
    })

    if (refetchRef) {
      refetchRef()
    }
  }

  const toggleFavourite = () => {
    favouriteMutation.mutate()
  }

  const showCommandDropdown = (commandMap ?? []).length > 8

  return (
    <Card>
      <div className="flex flex-col items-start xl:flex-row xl:items-center xl:justify-between">
        <div className="flex items-center space-x-4">
          <img
            alt={`object_${object.objectId}_icon`}
            className="my-auto h-16 w-16 rounded-md bg-gray-200 p-2"
            src={`https://api365.solver.no/api/Resource/Icon/${object.defaultIconId}/Image`}
          />
          <div className="min-w-0 flex-1">
            <div className="flex items-center space-x-2">
              {/* <h2 className="mt-2 text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
              Back End Developer
            </h2> */}
              {accessLevel === 'delegate' ? (
                <ObjectNameInput object={object} />
              ) : (
                <p
                  className="my-auto py-1 text-xl font-semibold"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {object.name}
                </p>
              )}
            </div>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              {/* <ObjectStatus accessLevel={accessLevel} object={object} /> */}

              <p className="text-sm text-gray-500">
                Object ID: {object.objectId}
              </p>

              {object.parentId && (
                <p className="text-sm text-gray-500">
                  Parent ID: {object.parentId}
                </p>
              )}
              {object.price && (
                <p className="text-sm text-gray-500">
                  Object Price: {object.price ? `${object.price} kr` : '--'}
                </p>
              )}
              {object.externalId && (
                <p className="text-sm text-gray-500">
                  External ID: {object.externalId}
                </p>
              )}
            </div>
            {object.statusText && (
              <p className="mt-0 text-sm text-gray-500 sm:mt-1">
                Status:{' '}
                {sortStatusTextByLanguage(
                  object.statusText.statusLines,
                  i18n.language
                )}
              </p>
            )}
          </div>
        </div>
        <div className="mt-5 flex gap-x-3 xl:ml-4 xl:mt-0">
          {showCommandDropdown && (
            <CommandDropdown
              commandMap={commandMap}
              accessLevel={accessLevel}
              id={object.objectId}
            />
          )}

          <MenuButton
            title={'Options'}
            options={[
              {
                key: 'toggle_public',
                title: object.isPublic ? 'Make private' : 'Make public',
                onClick: () => onTogglePublic(),
              },
              {
                key: 'toggle_active',
                title: object.active ? 'Mark inactive' : 'Mark active',
                onClick: () => onToggleActive(),
              },
            ]}
          />

          <MenuButton
            title="QR"
            options={(commandMap || []).map((cmd) => ({
              key: 'cmd',
              title: cmd.native,
              onClick: () => openQRFromCommand(cmd.native),
            }))}
          />

          {/* <>
              {showBookingButton && (
                <Button onClick={handleBook}>Planyo Book</Button>
              )}
            </> */}

          <ObjectIsOnline object={object} />
          <ObjectIsPublic object={object} />
          <ObjectIsActive object={object} />

          {/* <Dropdown label="Executing..." isLoading>
              <Dropdown.Item>Item 1</Dropdown.Item>
              <Dropdown.Item>Item 2</Dropdown.Item>
              <Dropdown.Item>Item 3</Dropdown.Item>
            </Dropdown> */}

          {accessLevel === 'access' && (
            <FavouriteControl
              isFavourite={isFavourite}
              submitting={isFavouriteLoading}
              object={object}
              onClick={toggleFavourite}
            />
          )}
        </div>
      </div>
      {showCommandDropdown || (
        <div className="mt-3">
          <ObjectCommands
            commandMap={commandMap}
            accessLevel={accessLevel}
            id={object.objectId}
          />
        </div>
      )}

      {object?.tags && object?.tags?.length > 0 && (
        <div className="mt-3">
          <ObjectTags tags={object.tags} />
        </div>
      )}
    </Card>
  )
}

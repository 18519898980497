class ApiHelper {
  _request = (url, method, body, options = {}) => {
    return fetch(url, {
      credentials: 'include',
      method: method,
      body: body,
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      if (res.status === 401) {
        window.location.pathname = '/login'
      }
      if (res.status < 204) {
        if (options.text) {
          return res.text()
        } else {
          return res.json()
        }
      }
    })
  }

  //  --- Groups ---
  searchGroups = (filterValue) => {
    let query = ''
    if (filterValue.length > 0) query = `?filterValue=${filterValue}`

    return this._request(`/api/groups${query}`, 'GET')
  }

  //  --- Tenants ---
  searchTenants = (query) => {
    return this._request(`/api/tenants/search?query=${query}`, 'GET')
  }

  //  --- Notifications ---
  createNotification = (body) => {
    return this._request('/api/notifications', 'POST', JSON.stringify(body))
  }

  //  --- Logs ---
  indexLogs = () => {
    return this._request('/api/logs', 'GET')
  }
  indexUserLogs = () => {
    return this._request('/api/userlogs', 'GET')
  }
  paymentLogs = () => {
    return this._request('/api/object/Payments', 'GET')
  }

  //  --- Objects ---
  createObject = (body, objectType) => {
    return this._request(
      `/api/objects?type=${objectType}`,
      'POST',
      JSON.stringify(body)
    )
  }

  /**
   * @deprecated
   */
  showAdminObject = (id, accessLevel) => {
    return this._request(
      `/api/objects/${id}/admin?accessLevel=${accessLevel}`,
      'GET'
    )
  }
  showObject = (id, accessLevel) => {
    let url = `/api/objects/${id}`

    if (accessLevel === 'user' || accessLevel === 'owner') {
      url = `${url}?accessLevel=${accessLevel}`
    }

    return this._request(url, 'GET')
  }
  updateObject = (id, newObject) => {
    return this._request(`/api/object/${id}`, 'PUT', JSON.stringify(newObject))
  }

  setObjectActive = (id, active) => {
    return this._request(`/api/object/${id}/active/${active}`, 'PUT')
  }

  updateObjectName = (id, objectName) => {
    return this._request(
      `/api/objects/${id}/name?objectName=${objectName}`,
      'PUT'
    )
  }

  //  --- Objects Relations ---
  getObjectMembers = (objectId) => {
    return this._request(`api/objects/${objectId}/relations/members`, 'GET')
  }

  addMemberToObject = (objectId, memberId) => {
    return this._request(
      `api/objects/${objectId}/relations/members/add/${memberId}`,
      'POST'
    )
  }

  removeMemberFromObject = (objectId, memberId) => {
    return this._request(
      `api/objects/${objectId}/relations/members/remove/${memberId}`,
      'DELETE'
    )
  }

  //  --- Objects Labels ---
  showObjectLabels = (objectId) => {
    return this._request(`api/objects/${objectId}/labels`, 'GET')
  }

  updateObjectLabels = (objectId, labels) => {
    return this._request(
      `api/objects/${objectId}/labels`,
      'PUT',
      JSON.stringify(labels)
    )
  }

  deleteObjectLabels = (objectId) => {
    return this._request(`api/objects/${objectId}/labels`, 'DELETE')
  }

  // Blob Edits
  showObjectExternalAuth = (id) => {
    return this._request(`api/objects/${id}/external-auth`, 'GET')
  }

  updateObjectExternalAuth = (id, authBlob) => {
    return this._request(
      `api/objects/${id}/external-auth`,
      'PUT',
      JSON.stringify(authBlob)
    )
  }

  showObjectInfoBlob = (id) => {
    return this._request(`api/objects/${id}/info-blob`, 'GET')
  }
  saveObjectInfoBlob = (id, infoBlob) => {
    return this._request(
      `api/objects/${id}/info-blob`,
      'PUT',
      JSON.stringify(infoBlob)
    )
  }

  showObjectVippsBlob = (id) => {
    return this._request(`api/objects/${id}/vipps-blob`, 'GET')
  }
  saveObjectVippsBlob = (id, vippsBlob) => {
    return this._request(
      `api/objects/${id}/vipps-blob`,
      'PUT',
      JSON.stringify(vippsBlob)
    )
  }

  showObjectGeofence = (id) => {
    return this._request(`api/objects/${id}/geofence-blob`, 'GET')
  }
  updateObjectGeofence = (id, geoBlob) => {
    return this._request(
      `api/objects/${id}/geofence-blob`,
      'PUT',
      JSON.stringify(geoBlob)
    )
  }
  // ---------------

  createTenant = (tenant) => {
    return this._request(`api/tenants`, 'POST', JSON.stringify(tenant))
  }

  //  --- Object Commands ---
  executeObjectCommand = (id, command, accessLevel, param) => {
    return this._request(
      `/api/objects/${id}/commands/${command}/${
        param || ''
      }?accessLevel=${accessLevel}`,
      'POST'
    )
  }

  //  --- Object Derivatives ---
  indexObjectTelemetry = (id, from, to) => {
    var query = `?from=${from}&to=${to}`

    return this._request(`/api/bridge/${id}/telemetry${query}`, 'GET')
  }

  indexObjectTelemetryTransformed = (id, from, to) => {
    var query = `?from=${from}&to=${to}`

    return this._request(`/api/bridge/${id}/telemetry/transform${query}`, 'GET')
  }

  indexObjectTransforms = (id) => {
    return this._request(`/api/bridge/${id}/transforms`, 'GET')
  }

  validateTransformExpression = (expression) => {
    return this._request(
      `/api/bridge/transformtest`,
      'POST',
      JSON.stringify({ expression })
    )
  }

  createObjectTransform = (id, transform) => {
    return this._request(
      `/api/bridge/${id}/transforms`,
      'POST',
      JSON.stringify(transform)
    )
  }

  deleteObjectTransform = (id, transformId) => {
    return this._request(
      `/api/bridge/${id}/transforms/${transformId}`,
      'DELETE'
    )
  }

  indexObjectTransactions = (id, from, to, showOpenOnly = false) => {
    var query = `?showOpenOnly=${showOpenOnly}`
    if (from) {
      query += `&from=${from}`
    }
    if (to) {
      query += `&to=${to}`
    }

    return this._request(`/api/objects/${id}/transactions${query}`, 'GET')
  }

  indexMyTransactions = (from, to, showOpenOnly = false, visitor = false) => {
    var query = `?showOpenOnly=${showOpenOnly}&visitor=${visitor}`
    if (from) {
      query += `&from=${from}`
    }
    if (to) {
      query += `&to=${to}`
    }

    return this._request(`/api/objects/my-transactions${query}`, 'GET')
  }

  indexMyObjectTransactions = (
    id,
    from,
    to,
    showOpenOnly = false,
    visitor = false
  ) => {
    var query = `?showOpenOnly=${showOpenOnly}&visitor=${visitor}`
    if (from) {
      query += `&from=${from}`
    }
    if (to) {
      query += `&to=${to}`
    }

    return this._request(`/api/objects/${id}/my-transactions${query}`, 'GET')
  }

  indexObjectLogs = (id) => {
    return this._request(`/api/objects/${id}/Logs`, 'GET')
  }

  indexUserObjectLogs = (id) => {
    return this._request(`/api/objects/${id}/userlogs`, 'GET')
  }

  //  --- Object Schedules ---
  createObjectSchedule = (id, body) => {
    return this._request(
      `api/objects/${id}/schedules`,
      'POST',
      JSON.stringify(body)
    )
  }

  showObjectSchedules = (id) => {
    return this._request(`api/objects/${id}/schedules`, 'GET')
  }

  showObjectScheduleHistory = (id) => {
    return this._request(`api/objects/${id}/schedules/history`, 'GET')
  }

  destroyObjectSchedules = (id, start, end) => {
    return this._request(
      `api/objects/${id}/schedules?start=${start}&end=${end}`,
      'DELETE'
    )
  }

  destroyCommand = (commandId) => {
    return this._request(`api/objects/commands/${commandId}`, 'DELETE')
  }

  editCommand = (commandId, data) => {
    return this._request(
      `api/objects/commands/${commandId}`,
      'PUT',
      JSON.stringify(data)
    )
  }

  //  --- Object Tenants ---
  createObjectTenant = (id, tenant) => {
    return this._request(
      `api/objects/${id}/tenants`,
      'POST',
      JSON.stringify(tenant)
    )
  }
  updateTenantPrice = (objectId, tenantId, data) => {
    return this._request(
      `api/objects/${objectId}/tenants/${tenantId}/price`,
      'PUT',
      JSON.stringify(data)
    )
  }
  showObjectTenants = (id) => {
    return this._request(`api/objects/${id}/tenants`, 'GET')
  }
  destroyObjectTenant = (id, tenantId) => {
    return this._request(`api/objects/${id}/tenants/${tenantId}`, 'DELETE')
  }
  toggleActivateObjectTenant = (objectId, tenantId, active) => {
    const body = {
      type: 'tenant',
      id: tenantId,
      active,
    }
    return this._request(
      `api/objects/${objectId}/access-activated`,
      'PUT',
      JSON.stringify(body)
    )
  }

  //  --- Object Children ---
  createChildObject = (id, body) => {
    return this._request(
      `/api/objects/${id}/child`,
      'POST',
      JSON.stringify(body)
    )
  }
  indexChildObjects = (id) => {
    return this._request(`/api/objects/${id}/child`, 'GET')
  }
  destroyChildObjects = (id) => {
    return this._request(`/api/objects/${id}/child`, 'DELETE')
  }

  //  --- Object Users ---
  indexObjectUsers = () => {
    return this._request('api/objects/users', 'GET')
  }
  indexObjectGroups = () => {
    return this._request('api/access/groups', 'GET')
  }
  indexObjectTenants = () => {
    return this._request('api/access/tenants', 'GET')
  }

  bulkAddTenantAccess = (objectIds, tenant) => {
    const promises = objectIds.map(
      (id) => () => this.createObjectTenant(id, tenant)
    )

    return Promise.all(promises.map((p) => Promise.resolve(p())))
  }

  bulkAddGroupAccess = (groupId, objectIds) => {
    const promises = objectIds.map(
      (o) => () => this.createObjectGroup(o, groupId)
    )

    return Promise.all(promises.map((p) => Promise.resolve(p())))
  }

  bulkAddUserAccess = (user, objectIds) => {
    const promises = objectIds.map(
      (o) => () =>
        this.createObjectUser(o, {
          userName: user.userName,
          userId: user.userId || 0,
          delegationLevel: user.delegationLevel,
        })
    )

    return Promise.all(promises.map((p) => Promise.resolve(p())))
  }

  createObjectUser = (objectId, newUser) => {
    return this._request(
      `api/objects/${objectId}/users`,
      'POST',
      JSON.stringify(newUser)
    )
  }

  updateUserPrice = (objectId, userId, data) => {
    return this._request(
      `api/objects/${objectId}/users/${userId}/price`,
      'PUT',
      JSON.stringify(data)
    )
  }

  showObjectUsers = (id, userType = 'delegated') => {
    return this._request(`api/objects/${id}/users?userType=${userType}`, 'GET')
  }
  destroyObjectUser = (objectId, userId, userType) => {
    return this._request(
      `api/objects/${objectId}/users/${userId}?userType=${userType}`,
      'DELETE'
    )
  }
  toggleActivateObjectUser = (objectId, userId, active) => {
    const body = {
      type: 'user',
      id: userId,
      active,
    }
    return this._request(
      `api/objects/${objectId}/access-activated`,
      'PUT',
      JSON.stringify(body)
    )
  }

  //  --- Object Groups ---
  createObjectGroup = (objectId, groupId) => {
    return this._request(`/api/objects/${objectId}/groups/${groupId}`, 'POST')
  }
  updateGroupPrice = (objectId, groupId, data) => {
    return this._request(
      `api/objects/${objectId}/groups/${groupId}/price`,
      'PUT',
      JSON.stringify(data)
    )
  }
  showObjectGroups = (objectId) => {
    return this._request(`/api/objects/${objectId}/groups`, 'GET')
  }
  destroyObjectGroup = (objectId, groupId) => {
    return this._request(`/api/objects/${objectId}/groups/${groupId}`, 'DELETE')
  }
  toggleActivateObjectGroup = (objectId, groupId, active) => {
    const body = {
      type: 'group',
      id: groupId,
      active,
    }
    return this._request(
      `api/objects/${objectId}/access-activated`,
      'PUT',
      JSON.stringify(body)
    )
  }

  //  --- Object Hooks ---
  createObjectHook = (webhookUrl) => {
    return this._request(`/api/object_hooks`, 'POST')
  }

  //  --- Object Status ---
  showObjectStatus = (id, accessLevel) => {
    return this._request(
      `/api/objects/${id}/status?objectType=${accessLevel}`,
      'GET'
    )
  }

  //  --- Surveys ---
  createUserSurvey = () => {
    return this._request('/api/greenstat-survey', 'POST')
  }
  indexUserSurveys = () => {
    return this._request('/api/greenstat-survey', 'GET')
  }
  destroyUserSurvey = (id) => {
    return this._request(`/api/greenstat-survey/${id}`, 'DELETE')
  }
  indexSurveySuggestions = () => {
    return this._request('/api/greenstat-survey/suggestions', 'GET')
  }
  completeSurvey = (guid, answers) => {
    return this._request(
      `/api/greenstat-survey/${guid}/completed`,
      'POST',
      JSON.stringify(answers)
    )
  }

  //  --- Tenant Users ---
  indexTenantUsers = () => {
    return this._request('api/tenants/users', 'GET')
  }

  indexTenantTenants = () => {
    return this._request('api/tenants/tenants', 'GET')
  }

  indexTenantGroups = () => {
    return this._request('api/tenants/groups', 'GET')
  }

  createTenantGroup = (group) => {
    return this._request(`/api/tenants/groups`, 'POST', JSON.stringify(group))
  }

  showGroup = (groupId) => {
    return this._request(`/api/group/${groupId}`, 'GET')
  }

  showTenant = (tenantId) => {
    return this._request(`/api/tenant/${tenantId}`, 'GET')
  }

  //  --- Users ---
  showUser = (userId) => {
    if (userId != null) {
      return this._request(`/api/user/${userId}`, 'GET')
    } else {
      return this._request(`/api/users`, 'GET')
    }
  }

  searchUsers = (filter) => {
    let query = ''
    if (filter.length > 0) query = `?query=${filter}`

    return this._request(`/api/users/search${query}`, 'GET')
  }
  showUserDetails = () => {
    return this._request(`/api/users/details`, 'GET')
  }

  showUserPayload = () => {
    return this._request(`/api/users/payload`, 'GET')
  }

  showMe = () => {
    return this._request('/api/users/me', 'GET')
  }

  showUserToken = () => {
    return this._request('/api/users/token', 'GET')
  }

  show365ApiVersion = () => {
    return this._request('/api/api-version/365', 'GET')
  }

  showSolverApiVersion = () => {
    return this._request('/api/api-version/solver', 'GET')
  }

  bulkRemoveUserDelegation = (userId, objects) => {
    return this._request(
      `api/users/${userId}/bulk/admin/remove`,
      'POST',
      JSON.stringify({ objects })
    )
  }

  // --- User ---
  indexFavouriteObjects = () => {
    return this._request('/api/users/objects/favourites', 'GET')
  }
  favouriteObject = (id) => {
    return this._request(`/api/users/objects/${id}/favourite`, 'POST')
  }
  unfavouriteObject = (id) => {
    return this._request(`/api/users/objects/${id}/favourite`, 'DELETE')
  }
  isObjectFavourited = (id) => {
    return this._request(`/api/users/objects/${id}/isFavourite`, 'GET')
  }

  //  --- User Objects ---
  indexUserObjects = () => {
    return this._request('/api/users/objects', 'GET')
  }

  queryObjects = (query) => {
    return this._request(
      '/api/users/objects/search?query=' + encodeURIComponent(query),
      'GET'
    )
  }

  indexDelegatedObjects = () => {
    return this._request('/api/users/objects?userType=delegated', 'GET')
  }
  showUserObject = (id) => {
    return this._request(`/api/users/${id}/objects`, 'GET')
  }
  showTenantObject = (id) => {
    return this._request(`/api/tenants/${id}/objects`, 'GET')
  }
  showGroupObject = (id) => {
    return this._request(`/api/groups/${id}/objects`, 'GET')
  }
  bulkRemoveUserAccess = (userId, objects) => {
    return this._request(
      `api/users/${userId}/bulk/access/remove`,
      'POST',
      JSON.stringify({ objects })
    )
  }
  bulkRemoveTenantAccess = (tenantId, objects) => {
    return this._request(
      `api/tenants/${tenantId}/bulk/access/remove`,
      'POST',
      JSON.stringify({ objects })
    )
  }
  bulkRemoveGroupAccess = (groupId, objects) => {
    return this._request(
      `api/groups/${groupId}/bulk/access/remove`,
      'POST',
      JSON.stringify({ objects })
    )
  }

  // -- Booking --

  createSignedUrl = (objectId) => {
    return this._request(`api/booking/CreateSignedURL/${objectId}`, 'PUT')
  }

  getSignedUrlObject = (signedUrl) => {
    return this._request(`api/booking/${signedUrl}`, 'GET')
  }

  // --- MS Graph ---
  listAdGroups = () => {
    return this._request(`/api/graph/groups/list`, 'GET')
  }
  verifyGraphToken = () => {
    return this._request(`/api/graph/oauth/verify`, 'GET')
  }
  saveGraphToken = (token) => {
    return this._request(
      `/api/graph/oauth/token`,
      'POST',
      JSON.stringify({ token })
    )
  }
  /**
   * @deprecated
   */
  syncGroup = (groupId) => {
    return this._request(`/api/graph/groups/sync/${groupId}`, 'POST')
  }
  syncGroupUsers = () => {
    return this._request(`/api/graph/groups/sync/users`, 'POST')
  }
  syncGroups = (groups) => {
    return this._request(
      `/api/graph/groups/sync`,
      'POST',
      JSON.stringify(groups)
    )
  }
}

export default new ApiHelper()

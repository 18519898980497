import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Button, DateInput, FlexibleModal } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { getDate } from 'helpers/DateTimeHelper'
import { startOfDay, endOfDay } from 'date-fns'

const DeleteObjectSchedule = ({ objectId, refetch, setVisible, visible }) => {
  //  --- variables ---
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState(getDate())
  const [endDate, setEndDate] = useState(getDate())

  //  --- functionality ---
  const onDestroy = useCallback(async () => {
    const sD = startOfDay(new Date(startDate)).toISOString()
    const eD = endOfDay(new Date(endDate)).toISOString()

    await toast.promise(ApiHelper.destroyObjectSchedules(objectId, sD, eD), {
      loading: 'Deleting object schedule.',
      success: 'Schedule deleted.',
      error: 'Failed to delete schedule.',
    })
    refetch()
    setVisible(false)
  }, [objectId, endDate, refetch, setVisible, startDate])

  //  --- response ---
  return (
    <FlexibleModal size="modal-sm" visible={visible}>
      <div className="flex h-full flex-col space-y-4">
        <h1 className="text-center text-lg">
          Please select the date from which to remove automations.
        </h1>
        <div className="flex flex-row items-center justify-between space-x-2">
          <span className="whitespace-nowrap text-sm">Start Date:</span>
          <div>
            <DateInput
              defaultValue={startDate}
              onChange={(d) => setStartDate(d)}
              showTimeSelect={false}
            />
          </div>
        </div>
        <div className="flex w-full flex-row items-center justify-between space-x-2">
          <span className="whitespace-nowrap text-sm">End Date:</span>
          <div>
            <DateInput
              defaultValue={endDate}
              onChange={(d) => setEndDate(d)}
              showTimeSelect={false}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end space-x-4">
          <Button
            className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
            onClick={() => setVisible(false)}
          >
            {t('application:cancel')}
          </Button>
          <Button
            className="bg-green-400 text-white hover:bg-green-500"
            onClick={onDestroy}
          >
            {t('application:delete')}
          </Button>
        </div>
      </div>
    </FlexibleModal>
  )
}

export { DeleteObjectSchedule }

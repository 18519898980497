import React from 'react'

const ObjectTags = ({ tags }) => {
  if (tags === undefined || tags.length === 0) return null
  return (
    <div className="space-y-1">
      <p className="my-auto text-sm text-gray-400">Tags</p>
      <div>
        {tags.map((tag) => (
          <span
            className="my-auto mr-1 rounded bg-gray-200 p-1 text-gray-800"
            key={tag.objectTagId}
          >
            {tag.tagId}
          </span>
        ))}
      </div>
    </div>
  )
}

export default ObjectTags

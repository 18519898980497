import React from 'react'
import { useTranslation } from 'react-i18next'

const DeleteSurveyButton = ({ row, onDelete }) => {
  const { t } = useTranslation()

  if (!row.completed && row.isOwner)
    return (
      <button
        className={`focus:outline-none my-auto h-7 rounded-md border bg-gray-100 px-2 hover:bg-gray-300`}
        onClick={!row.completed ? onDelete : undefined}
      >
        {t('application:delete')}
      </button>
    )
  return (
    <div
      className={`focus:outline-none my-auto h-7 cursor-not-allowed rounded-md border bg-gray-100 px-2 opacity-30 hover:bg-gray-300`}
    >
      {t('application:delete')}
    </div>
  )
}

export default DeleteSurveyButton

import React from 'react'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { FlexibleModal, Button } from 'components'
import { UserSearchTable } from 'containers'
import ApiHelper from 'helpers/ApiHelper'

const UserAccessModal = ({ objectIds, visible, toggle, onDone }) => {
  const { t } = useTranslation()

  const onAddAccessBulk = async (user) => {
    await toast.promise(ApiHelper.bulkAddUserAccess(user, objectIds()), {
      loading: 'Creating access...',
      success: 'Access rights updated.',
      error: 'Could not add access rights.',
    })

    onDone()
  }

  return (
    <FlexibleModal size="modal-md" visible={visible}>
      <div className="flex flex-col space-y-2">
        <UserSearchTable
          id="add-access-bulk"
          onSubmit={onAddAccessBulk}
          tableHeight={300}
        />
        <div className="flex justify-end pt-10">
          <Button onClick={toggle}>{t('application:cancel')}</Button>
        </div>
      </div>
    </FlexibleModal>
  )
}

export default UserAccessModal

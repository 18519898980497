import { useState, useCallback } from 'react'

const useFileDownload = () => {
  const [fetching, setFetching] = useState(false)

  const fetchFile = useCallback(
    (url, filename) => {
      setFetching(true)
      const finalizedUrl = typeof url === 'function' ? url() : url
      document.body.style.cursor = 'progress'
      fetch(finalizedUrl)
        .then((r) => r.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = filename
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          setFetching(false)
          document.body.style.cursor = 'default'
        })
        .catch(() => {
          document.body.style.cursor = 'default'
          setFetching(false)
        })
    },
    [setFetching]
  )

  return { fetching, fetchFile }
}

export default useFileDownload

import ObjectAdministratorTable from './ObjectAdministratorTable'
import ObjectScheduleTable from './ObjectScheduleTable'
import ObjectStatusTable from './ObjectStatusTable'
import ObjectRelationsTable from './ObjectRelationsTable'
import ObjectChildrenTable from './ObjectChildrenTable'
import ObjectTenantAccessTable from './ObjectTenantAccessTable'
import ObjectUserAccessTable from './ObjectUserAccessTable'

import {
  MessagesTab,
  ObjectGroupTable,
  ObjectLogsTable,
  PaymentsTab,
  ExternalAuthTab,
  SubscriptionTab,
} from 'containers'

// Info & Geofence are not imported from containers or object tables
import Geofence from '../Forms/Geofence'
import ObjectLabelsForm from '../Forms/ObjectLabelsForm'
import { EditObjectInformation } from '../Forms/ObjectInformation/EditObjectInformation'

const adminTableTypes = {
  ObjectLabelsForm: ObjectLabelsForm,
  GeoFence: Geofence,
  EditObjectInformation: EditObjectInformation,
  ObjectLogsTable: ObjectLogsTable,
  ObjectAdministratorTable: ObjectAdministratorTable,
  ObjectRelationsTable: ObjectRelationsTable,
  ObjectChildrenTable: ObjectChildrenTable,
  ObjectScheduleTable: ObjectScheduleTable,
  ObjectStatusTable: ObjectStatusTable,
  ObjectTenantAccessTable: ObjectTenantAccessTable,
  ObjectUserAccessTable: ObjectUserAccessTable,
  ObjectGroupTable: ObjectGroupTable,
  PaymentsTab: PaymentsTab,
  ExternalAuthTab: ExternalAuthTab,
  MessagingTab: MessagesTab,
  SubscriptionTab: SubscriptionTab,
}

export { adminTableTypes }

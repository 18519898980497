import React from 'react'
import { Card } from 'components'
import config from 'config'

const LoginView = () => {
  return (
    <div className="h-full">
      <Card cardType="login">
        <div className="border-b border-gray-300 pb-2 text-center">
          <h1 className="text-3xl font-semibold text-gray-600">
            {config.appName}
          </h1>
        </div>
        <div className="mt-8 flex flex-col space-y-6">
          <button
            className="btn"
            onClick={() => window.open('/api/auth/login', '_self')}
            style={{ padding: 0 }}
          >
            <img
              className="mx-auto hover:shadow-xl"
              src="/images/login_ms.png"
              alt="Office Login Button"
            ></img>
          </button>
          <button
            className="btn"
            onClick={() =>
              window.open('/api/auth/login?provider=vipps', '_self')
            }
            style={{ padding: 0 }}
          >
            <img
              className="mx-auto hover:shadow-xl"
              src="/images/login_vipps.png"
              alt="Vipps Login Button"
            ></img>
          </button>
        </div>
      </Card>
      <span className="absolute bottom-2 left-2 text-xs text-gray-500">
        SolverPortal v{config.version} ({config.build})
      </span>
    </div>
  )
}

export default LoginView

import { Button } from 'components'
import React, { useState } from 'react'
import { FormField } from './FormField'

const NestedForm = ({ label, schema, multiple = false, prefix, disabled }) => {
  const [items, setItems] = useState([schema])

  return (
    <div>
      <div className="my-auto flex flex-row justify-between border-b-2 border-gray-100 pl-2">
        <span>{label}</span>
        {multiple && (
          <Button onClick={() => setItems((i) => [...i, schema])}>Add</Button>
        )}
      </div>
      <div className="ml-2 pt-2">
        {items.map((item, k) => (
          <FormBuilder
            compact={true}
            key={`${item.name}-${k}`}
            prefix={multiple ? `${prefix}[${k}]` : prefix}
            schema={item}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  )
}

const FormBuilder = ({
  schema,
  compact = false,
  prefix = undefined,
  disabled = false,
}) => {
  return (
    <fieldset
      className="flex w-full flex-row flex-wrap space-y-2"
      disabled={disabled}
    >
      {schema.map(({ name, label, ...props }) =>
        props.schema != null ? (
          <NestedForm
            label={label}
            prefix={name}
            disabled={disabled}
            {...props}
          />
        ) : (
          <div
            key={name}
            className="flex w-1/2 flex-row justify-between space-x-4"
          >
            {compact ? (
              <FormField
                name={prefix ? `${prefix}.${name}` : name}
                placeholder={label}
                {...props}
              />
            ) : (
              <>
                <p className="my-auto pl-2">{label}</p>
                <FormField
                  name={prefix ? `${prefix}.${name}` : name}
                  {...props}
                />
              </>
            )}
          </div>
        )
      )}
    </fieldset>
  )
}

export default FormBuilder

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Card, FilteredTable } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { prefetchQuery } from 'helpers/QueryHelper'
import UserTypeIcon from 'components/shared/Users/UserTypeIcon'

const AdminObjectGroupsIndexView = () => {
  //  --- Variables ---
  let history = useHistory()
  const { t } = useTranslation()

  //  --- Response ---
  return (
    <Card cardType="primary-content" className="mb-4 h-full">
      <FilteredTable
        apiName="indexObjectGroups"
        dataApi={() => ApiHelper.indexObjectGroups()}
        filterCacheKey="indexObjectGroup"
        initialSort={{ id: 'groupName', desc: false }}
        placeholder={t('application:search')}
        removeFooter={true}
        filterDebounce={true}
        scrollRestore={true}
        rowOnClick={(row) => {
          prefetchQuery('group', row.groupId, row)
          history.push(`/access/groups/${row.groupId}`)
        }}
        tableHeaders={tableHeaders}
      />
    </Card>
  )
}

const tableHeaders = [
  // {
  //   Header: 'ID',
  //   i18n: 'indexObjects:objectUsersTable.id',
  //   accessor: 'groupId',
  //   width: 70,
  // },
  {
    Header: 'Display Name',
    i18n: 'indexObjects:objectUsersTable.displayName',
    accessor: 'groupName',
    width: 300,
  },
  {
    Header: 'SID',
    i18n: 'indexObjects:objectUsersTable.sid',
    accessor: 'sid',
    width: 400,
  },
  {
    Header: 'Type',
    i18n: 'indexObjects:objectUsersTable.type',
    accessor: 'userTypeId',
    width: 50,
    Cell: (props) => <UserTypeIcon userTypeId={1} />,
  },
  {
    Header: 'Tenant',
    i18n: 'indexObjects:objectUsersTable.tenant',
    accessor: 'tenantName',
    width: 100,
  },
]

export default AdminObjectGroupsIndexView

import React from 'react'
import Icon from './Icon'

const ActiveIcon = ({ active, className }) => {
  return (
    <Icon
      className={className ? className : undefined}
      iconName="check"
      color={active ? 'text-green-600' : 'text-gray-200'}
    />
  )
}

export default ActiveIcon

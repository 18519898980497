import React, { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { GroupSearchTable } from '../..'
import { Button, FilteredTable, MenuButton, FlexibleModal } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { toLocalTime } from 'helpers/DateTimeHelper'
import EditPrice from '../Forms/EditPrice'

const ObjectGroupTable = ({ id }) => {
  //  --- Variables ---
  const refreshRef = useRef()
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [visibility, setVisibility] = useState(false)
  const { t } = useTranslation()

  const deleteGroupAccess = async (groupId) => {
    await toast.promise(ApiHelper.destroyObjectGroup(id, groupId), {
      loading: 'Removing group...',
      success: 'Group removed.',
      error: 'Could not remove group.',
    })
    if (refreshRef.current) {
      refreshRef.current()
    }
  }

  const toggleGroupAccess = async (active, groupId) => {
    await toast.promise(
      ApiHelper.toggleActivateObjectGroup(id, groupId, active),
      {
        loading: `${active ? 'Activating' : 'Deactivating'} group access...`,
        success: `Group access ${active ? 'activated' : 'deactivated'}`,
        error: `Could not ${active ? 'activate' : 'deactivate'} group access`,
      }
    )
    if (refreshRef.current) {
      refreshRef.current()
    }
  }

  const onGroupAccessAction = async (action, group) => {
    switch (action) {
      case 'delete':
        await deleteGroupAccess(group.groupId)
        return
      case 'activation':
        await toggleGroupAccess(!group.active, group.groupId)
        return
      case 'price':
        setSelectedGroup(group)
        return
      default:
        return
    }
  }

  const onAddGroupSubmit = async (group) => {
    try {
      await toast.promise(ApiHelper.createObjectGroup(id, group.groupId), {
        loading: 'Adding group...',
        success: 'Group Added.',
        error: 'Could not add group.',
      })
      setVisibility(false)
      if (refreshRef.current) {
        refreshRef.current()
      }
    } catch (err) {}
  }

  const onEditGroupPrice = async (form) => {
    try {
      await toast.promise(
        ApiHelper.updateGroupPrice(id, selectedGroup.groupId, form),
        {
          loading: 'Saving price...',
          success: 'Group pricing saved.',
          error: 'Could not save group pricing.',
        }
      )
      setSelectedGroup(null)
      if (refreshRef.current) {
        refreshRef.current()
      }
    } catch (err) {}
  }

  //  --- Response ---
  return (
    <div className="h-full">
      <FilteredTable
        apiName={[`showObjectGroups`, id]}
        dataApi={() => ApiHelper.showObjectGroups(id)}
        filterDebounce={true}
        initialSort={{ id: 'groupName', desc: true }}
        placeholder={t('application:search')}
        queryProps={{
          refetchOnWindowFocus: false,
        }}
        refreshRef={refreshRef}
        removeFooter={true}
        // showShimmer={{ columnCount: 1 }}
        showRefreshButton={true}
        tableHeaders={tableHeaders(onGroupAccessAction)}
      >
        <Button onClick={() => setVisibility(true)}>Add Group</Button>
      </FilteredTable>
      <FlexibleModal size="modal-md" visible={visibility}>
        <div className="flex flex-col space-y-6">
          <GroupSearchTable id={id} onSubmit={onAddGroupSubmit} />
          <div className="flex justify-end">
            <Button onClick={() => setVisibility(false)}>Close</Button>
          </div>
        </div>
      </FlexibleModal>
      {selectedGroup && (
        <EditPrice
          title={'Group price'}
          object={selectedGroup}
          onSubmit={onEditGroupPrice}
          onCancel={(e) => setSelectedGroup(null)}
        />
      )}
    </div>
  )
}

const tableHeaders = (onAction) => {
  return [
    // {
    //   Header: 'ID',
    //   i18n: 'indexObjects:objectGroupsTable.id',
    //   accessor: 'groupId',
    //   width: 80,
    // },
    {
      Header: 'Actions',
      i18n: 'indexObjects:objectGroupsTable.actions',
      accessor: 'nothing',
      width: 100,
      Cell: (props) => (
        <MenuButton
          title="More"
          small={true}
          pinRight={false}
          options={[
            {
              key: 'price',
              title: 'Edit price',
              onClick: () => onAction('price', props.row.original),
            },
            {
              key: 'activation',
              title: props.row.original.active ? 'Deactivate' : 'Activate',
              onClick: () => onAction('activation', props.row.original),
            },
            {
              key: 'delete',
              title: 'Delete',
              onClick: () => onAction('delete', props.row.original),
            },
          ]}
        />
      ),
    },
    {
      Header: 'Active',
      i18n: 'showObject:tabs.active',
      accessor: 'active',
      width: 80,
      Cell: (props) => (
        <CheckCircleIcon
          className={`mx-auto h-5 w-5 ${
            props.row.original.active ? 'text-green-400' : 'text-gray-200'
          }`}
        />
      ),
    },
    {
      Header: 'Name',
      i18n: 'indexObjects:objectGroupsTable.name',
      accessor: 'groupName',
      width: 250,
    },
    {
      Header: 'Tenant',
      i18n: 'indexObjects:objectGroupsTable.tenantId',
      accessor: 'tenantName',
      width: 120,
    },
    {
      Header: 'Price',
      i18n: 'showObject:tabs.price',
      accessor: 'price',
      width: 100,
    },
    // {
    //   Header: 'Online Payment',
    //   accessor: 'onlinePayment',
    //   width: 150,
    //   Cell: (props) => (props.row.original.onlinePayment ? 'Yes' : 'No'),
    // },
    {
      Header: 'Online Prc',
      accessor: 'onlinePrice',
      width: 100,
      Cell: ({ row: { original } }) => (
        <div className="text-right">
          {original.onlinePayment ? original.onlinePrice : ''}
        </div>
      ),
    },
    // {
    //   Header: 'Can Invoice',
    //   accessor: 'canInvoice',
    //   width: 110,
    //   Cell: (props) => (props.row.original.canInvoice ? 'Yes' : 'No'),
    // },
    {
      Header: 'Invoice Prc',
      accessor: 'invoicePrice',
      width: 110,
      Cell: ({ row: { original } }) => (
        <div className="text-right">
          {original.canInvoice ? original.invoicePrice : ''}
        </div>
      ),
    },
    {
      Header: 'Date',
      i18n: 'showObject:tabs.createdBy',
      accessor: 'createdBy',
      width: 280,
      Cell: (props) => props.row.original.createdBy,
    },
    {
      Header: 'Date',
      i18n: 'showObject:tabs.createdAt',
      accessor: 'createdAt',
      width: 180,
      Cell: (props) => toLocalTime(props.row.original.createdAt),
    },
  ]
}

export default ObjectGroupTable

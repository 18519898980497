import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Button, FlexibleModal, TextInput } from 'components'
import ApiHelper from 'helpers/ApiHelper'

const CreateMasterlockModal = ({ visible, toggle }) => {
  const { t } = useTranslation()
  const [form, setForm] = useState({})

  const createObject = async () => {
    await toast.promise(ApiHelper.createObject(form, 'masterlock'), {
      loading: 'Creating New Lock Object.',
      success: 'Lock created.',
      error: 'Lock could not be created.',
    })

    setForm({})
    toggle()
  }

  return (
    <FlexibleModal size="modal-md" visible={visible}>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-4">
          <label className="flex justify-between">
            <p className="my-auto">Device Identifier</p>
            <TextInput
              className="w-2/3"
              onChange={(e) =>
                setForm((f) => ({ ...f, DeviceIdentifier: e.target.value }))
              }
              value={form.DeviceIdentifier}
            />
          </label>
          <label className="flex justify-between">
            <p className="my-auto">Activation Code</p>
            <TextInput
              className="w-2/3"
              onChange={(e) =>
                setForm((f) => ({ ...f, ActivationCode: e.target.value }))
              }
              value={form.ActivationCode}
            />
          </label>
          <label className="flex justify-between">
            <p className="my-auto">Name</p>
            <TextInput
              className="w-2/3"
              onChange={(e) => setForm((f) => ({ ...f, Name: e.target.value }))}
              value={form.Name}
            />
          </label>
        </div>
        <div className="flex flex-row justify-end space-x-4">
          <Button
            className="border border-red-200 bg-white text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
            onClick={toggle}
          >
            {t('application:cancel')}
          </Button>
          <Button
            className="bg-green-400 text-white hover:bg-green-500"
            onClick={createObject}
          >
            {t('application:submit')}
          </Button>
        </div>
      </div>
    </FlexibleModal>
  )
}

export default CreateMasterlockModal

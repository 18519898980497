import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { currentDateTime } from 'helpers/DateTimeHelper'

const AuthenticatedView = () => {
  //  --- Variables ---
  const history = useHistory()

  //  --- Setup ---
  useEffect(() => {
    localStorage.setItem('authStatus', 'true')
    localStorage.setItem('authDate', currentDateTime())
    history.push('/home')
  }, [history])

  //  --- Response ---
  return <div />
}

export default AuthenticatedView

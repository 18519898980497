import { Switch } from '@headlessui/react'
import cn from 'classnames'

const ToggleInput = ({ checked, onChange, textChecked, textUnchecked }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={cn(
        'focus:outline-none relative inline-flex h-6 w-20 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75',
        !checked ? 'bg-gray-500' : 'bg-green-400'
      )}
    >
      <span
        className={cn(
          'absolute top-0 text-sm text-white',
          checked ? 'left-2' : 'right-2'
        )}
      >
        {checked ? textChecked : textUnchecked}
      </span>
      <span
        aria-hidden="true"
        className={cn(
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          checked ? 'translate-x-14' : 'translate-x-0'
        )}
      />
    </Switch>
  )
}

export default ToggleInput

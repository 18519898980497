import React, { useCallback, useState } from 'react'
import cn from 'classnames'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import {
  Button,
  DateInput,
  FlexibleModal,
  SelectInput,
  TickBox,
} from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { getDate } from 'helpers/DateTimeHelper'
import { ObjectScheduleTask } from './ObjectScheduleTask'

const CreateObjectSchedule = ({
  commandMap,
  objectId,
  refetch,
  setVisible,
  visible,
}) => {
  //  --- variables ---
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState(getDate('thisMorning'))
  const [endDate, setEndDate] = useState(getDate())
  const [workDays, setWorkDays] = useState(false)
  const [countryISO, setCountryISO] = useState('NO')
  const [tasks, setTasks] = useState([])
  const [selectedDays, setSelectedDays] = useState([])

  //  --- functionality ---

  const numberOfInvalidTasks = tasks.filter((t) => !t.isValid).length

  const isValidTimeFormat = (timeString) => {
    const re = /^([01]\d|2[0-3]):([0-5]\d)$/

    return re.test(timeString)
  }

  const onAddTask = useCallback(() => {
    setTasks((tasks) => [
      ...tasks,
      { command: commandMap[0], time: '', commandParam: '', isValid: false },
    ])
  }, [setTasks, commandMap])

  const onChangeTask = useCallback(
    (index, task) => {
      const t = task.time

      const newTask = {
        ...task,
        isValid: isValidTimeFormat(t),
      }

      setTasks((tasks) => {
        let updatedTasks = [...tasks]
        updatedTasks[index] = { ...newTask }
        return updatedTasks
      })
    },
    [setTasks]
  )

  const onDestroyTask = useCallback(
    (index) => {
      setTasks((tasks) => {
        let updatedTasks = [...tasks]
        updatedTasks.splice(index, 1)
        return updatedTasks
      })
    },
    [setTasks]
  )

  const onScheduleCreate = async () => {
    const fD = new Date(startDate)
    const fDOffset = fD.getTimezoneOffset() * 60000
    const fDDateString = new Date(fD.getTime() - fDOffset).toISOString()
    const generateFromDate = fDDateString.substring(0, fDDateString.length - 1)

    const tD = new Date(endDate)
    const tDOffset = tD.getTimezoneOffset() * 60000
    const tDDateString = new Date(tD.getTime() - tDOffset).toISOString()
    const generateToDate = tDDateString.substring(0, tDDateString.length - 1)

    const body = {
      generateFromDate: generateFromDate.substring(
        0,
        generateFromDate.indexOf('.')
      ),
      generateToDate: generateToDate.substring(0, generateToDate.indexOf('.')),
      countryISO: countryISO,
      workDays: workDays,
      commands: tasks.map((task) => {
        const ds = new Date(startDate)
        const d = ds.toISOString()
        const executeTime = `${d.substring(0, d.indexOf('T'))}T${task.time}:00`

        return {
          commandId: 0,
          executeTime,
          command: task.command.native,
          commandParam: task.commandParam,
        }
      }),
      executeDays: selectedDays,
    }

    await toast.promise(ApiHelper.createObjectSchedule(objectId, body), {
      loading: 'Creating object schedule.',
      success: 'Schedule created',
      error: 'Failed to create schedule.',
    })

    refetch()
    setTasks([])
    setSelectedDays([])
    setVisible(false)
  }

  //  --- response ---
  return (
    <FlexibleModal size="modal-md" visible={visible}>
      <div className="flex h-full flex-col space-y-4">
        <div className="grid w-full grid-cols-6 items-center">
          <div className="cols-span-2">
            <p>Country</p>
          </div>
          <div className="col-span-2">
            <SelectInput
              className="w-full"
              options={[
                {
                  value: 'NO',
                  label: 'Norway',
                },
              ]}
              onChange={(e) => {
                setCountryISO(e.target.value)
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-6 items-center">
          <div className="cols-span-2">
            <p>Start Date:</p>
          </div>
          <div className="col-span-3">
            <DateInput
              defaultValue={startDate}
              onChange={(d) => setStartDate(d)}
              showTimeSelect={false}
            />
          </div>
        </div>
        <div className="grid grid-cols-6 items-center">
          <div className="cols-span-2">
            <p>End Date:</p>
          </div>
          <div className="col-span-3">
            <DateInput
              defaultValue={endDate}
              onChange={(d) => setEndDate(d)}
              showTimeSelect={false}
            />
          </div>
        </div>
        <div>
          <div className="flex flex-row justify-between border-b-2 pb-2">
            <span>Tasks</span>
            <Button onClick={onAddTask}>Add</Button>
          </div>
          {!tasks.length && (
            <div className="py-4 italic text-gray-500">No tasks</div>
          )}
          {tasks.map((task, index) => (
            <ObjectScheduleTask
              key={`scheduled_task_${index}`}
              command={task.command}
              isValid={task.isValid ?? false}
              commandParam={task.commandParam}
              commandMap={commandMap}
              index={index}
              time={task.time}
              onChange={onChangeTask}
              onDestroy={onDestroyTask}
            />
          ))}
        </div>
        <div>
          <div className="grid w-full grid-cols-6 items-center space-x-2">
            <div className="col-span-1">
              <h3>Days</h3>
            </div>

            <div className=" col-span-5 grid grid-cols-7 gap-x-2">
              {/* Array of length 7 */}
              {[...Array(7)].map((_, index) => {
                const workday = index + 1

                const isSelected = selectedDays.includes(workday)

                const addDay = () => {
                  if (isSelected) {
                    setSelectedDays((days) => days.filter((d) => d !== workday))
                  } else {
                    setSelectedDays((days) => [...days, workday])
                  }
                }

                return (
                  <div
                    onClick={addDay}
                    className={cn(
                      'cursor-pointer rounded border px-1.5 py-1 text-center text-sm font-medium',
                      isSelected
                        ? 'border-green-400 bg-green-400 text-white'
                        : 'border-gray-100 bg-gray-100 hover:bg-green-400 hover:text-white'
                    )}
                  >
                    {weekDayInitials[index]}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="mt-2 flex justify-end">
            <span className="text-xs italic">
              {selectedDays.length === 0
                ? ''
                : selectedDays.length === 7
                ? 'All days selected'
                : selectedDays.length === 1
                ? '1 day selected'
                : `${selectedDays.length} days selected`}
            </span>
          </div>
        </div>

        <div className="flex flex-row items-center justify-end space-x-2">
          <p>Work days:</p>
          <TickBox
            className="h-5 w-5"
            onChange={(e) => setWorkDays(e.target.checked)}
          />
        </div>

        {/* Footer */}
        <div className="flex flex-row justify-end space-x-4">
          <Button
            className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
            onClick={() => {
              setVisible(false)
              setSelectedDays([])
              setTasks([])
            }}
          >
            {t('application:cancel')}
          </Button>
          <Button
            className="bg-green-400 text-white hover:bg-green-500"
            disabled={numberOfInvalidTasks > 0 || !tasks || tasks.length === 0}
            onClick={() => {
              if (numberOfInvalidTasks === 0) {
                onScheduleCreate()
              }
            }}
          >
            {t('application:submit')}
          </Button>
        </div>
      </div>
    </FlexibleModal>
  )
}

const weekDayInitials = ['M', 'T', 'W', 'T', 'F', 'S', 'S']

export { CreateObjectSchedule }

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './locales/en'
import no from './locales/no'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: en,
      no: no,
    },
  })

let language = localStorage.getItem('i18nextLng')
if (language !== 'en' && language !== 'no') i18n.changeLanguage('en')

export default i18n

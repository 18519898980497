import { useCallback, useState } from 'react'
import cn from 'classnames'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { Button, FlexibleModal, SelectInput } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { withOrWithoutZ } from 'helpers/DateTimeHelper'

/**
 * This entire thing needs to be rewritten.
 */

const EditObjectSchedule = ({
  commandMap,
  objectId,
  refetch,
  setSchedule,
  schedule,
}) => {
  //  --- variables ---
  const { t } = useTranslation()
  const utcDate = new Date(withOrWithoutZ(schedule.originalTime))

  // const executeTime = dayjs(utcDate).format('HH:mm')
  const executeTime = format(utcDate, 'HH:mm')

  const isValidTimeFormat = (timeString) => {
    const re = /^([01]\d|2[0-3]):([0-5]\d)$/

    return re.test(timeString)
  }

  const [task, setTask] = useState({
    command: schedule.command,
    time: executeTime,
    isValid: isValidTimeFormat(executeTime),
  })

  //  --- functionality ---
  const isTaskInvalid = !task.isValid || false

  const onChangeTask = useCallback((task) => {
    const t = task.time

    const newTask = {
      time: t,
      command: task?.command,
      isValid: isValidTimeFormat(t),
    }

    setTask(newTask)
  }, [])

  const onScheduleUpdate = async () => {
    const body = {
      commandId: schedule.commandId,
      objectId,
      executeTime:
        schedule.executeTime.substring(0, schedule.executeTime.indexOf(' ')) +
        'T' +
        task.time +
        ':00',
      command: task?.command,
    }

    await toast.promise(ApiHelper.editCommand(schedule.commandId, body), {
      loading: 'Updating command.',
      success: 'Command updated',
      error: 'Failed to update command.',
    })

    refetch()
    setSchedule(null)
    setTask(null)
  }

  //  --- response ---
  return (
    <FlexibleModal size="modal-md" visible={!!schedule}>
      <div className="flex h-full flex-col space-y-4">
        <div className="my-2">
          <ObjectScheduleTask
            task={task}
            key={`scheduled_task`}
            command={task?.command}
            isValid={task.isValid ?? false}
            commandMap={commandMap}
            time={task.time}
            onChange={onChangeTask}
          />
        </div>

        <div className="flex flex-row justify-end space-x-4">
          <Button
            className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
            onClick={() => {
              setSchedule(null)
              setTask(null)
            }}
          >
            {t('application:cancel')}
          </Button>
          <Button
            className="bg-green-400 text-white hover:bg-green-500"
            disabled={isTaskInvalid}
            onClick={() => {
              if (!isTaskInvalid) {
                onScheduleUpdate()
              }
            }}
          >
            {t('application:submit')}
          </Button>
        </div>
      </div>
    </FlexibleModal>
  )
}

export { EditObjectSchedule }

const ObjectScheduleTask = ({
  task,
  commandMap,
  onChange,
  isValid,
  command,
  time,
}) => {
  //  --- functionality ---
  // TODO
  // const getComandByNative = (native) => {
  //   return commandMap.find((c) => c.native === native)
  // }

  const onCommandChange = (e) => {
    onChange({ command: e.target.value, time })
  }

  const onTimeChange = (newTime) => {
    onChange({ time: newTime, command })
  }

  //  --- response ---
  return (
    <div className="flex flex-row space-x-3 border-b-2 py-1">
      <SelectInput
        className="w-2/5"
        options={commandMap.map((command) => ({
          value: command.native,
          label: command.native,
        }))}
        value={task?.command}
        onChange={onCommandChange}
      />
      <input
        className={cn(
          `rounded border-2 px-2 py-2`,
          !isValid ? 'border-red-400' : 'border-gray-300'
        )}
        placeholder={`HH:mm`}
        value={task.time}
        onChange={(e) => {
          onTimeChange(e.target.value)
        }}
      />
    </div>
  )
}

import React from 'react'
import { TickBox } from '..'

const TogglableTickBox = ({ isEditable, onChange, placeholder }) => {
  if (!isEditable)
    return (
      <div className="h-9 px-1 py-1.5">
        <p className="my-auto">{placeholder ? 'True' : 'False'}</p>
      </div>
    )
  return <TickBox showValue={true} value={placeholder} onChange={onChange} />
}

export default TogglableTickBox

import React from 'react'
import VippsCredentials from 'containers/SolverAdmin/Forms/VippsCredentials'
import PaymentInfo from 'containers/SolverAdmin/Forms/PaymentInfo'
import { ShimmerCard } from 'components'

const PaymentsTab = ({ object, tabUtils }) => {
  //  --- Response ---
  if (tabUtils.isFetchingObject) {
    return (
      <ShimmerCard columnCount={1} />
    )
  }

  return (
    <div className="mb-5">
      <div className="flex flex-col space-y-5 border-b pb-5 lg:flex-row lg:space-x-5 lg:space-y-0 lg:border-b-0 lg:pb-0">
        <div className="flex w-full flex-col space-y-4">
          <PaymentInfo object={object} tabUtils={tabUtils} />
          <VippsCredentials id={object.objectId} />
        </div>
      </div>
    </div>
  )
}

export default PaymentsTab

import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FilteredTable, MenuButton, FlexibleModal } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import TenantSearchTable from 'containers/ObjectTables/TenantSearchTable'
import toast from 'react-hot-toast'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { toLocalTime } from 'helpers/DateTimeHelper'
import EditPrice from '../Forms/EditPrice'

const ObjectTenantAccessTable = ({ id }) => {
  const refreshRef = useRef()
  const { t } = useTranslation()
  const [selectedTenant, setSelectedTenant] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  const deleteTenantAccess = async (tenantId) => {
    await toast.promise(ApiHelper.destroyObjectTenant(id, tenantId), {
      loading: 'Removing tenant...',
      success: 'Tenant removed',
      error: 'Could not remove tenant.',
    })
    if (refreshRef.current) {
      refreshRef.current()
    }
  }

  const toggleTenantAccess = async (active, tenantId) => {
    await toast.promise(
      ApiHelper.toggleActivateObjectTenant(id, tenantId, active),
      {
        loading: `${active ? 'Activating' : 'Deactivating'} tenant access...`,
        success: `Tenant access ${active ? 'activated' : 'deactivated'}`,
        error: `Could not ${active ? 'activate' : 'deactivate'} tenant access`,
      }
    )
    if (refreshRef.current) {
      refreshRef.current()
    }
  }

  const onTenantAccessAction = async (action, tenant) => {
    switch (action) {
      case 'delete':
        await deleteTenantAccess(tenant.tenantId)
        return
      case 'activation':
        await toggleTenantAccess(!tenant.active, tenant.tenantId)
        return
      case 'price':
        setSelectedTenant(tenant)
        return
      default:
        return
    }
  }

  const onSubmitTenantAccess = async (form) => {
    const tenant = {
      tenantId: form.tenantId || 0,
      primaryDomain: form.primaryDomain,
      name: form.tenantId ? form.name : '',
      adminId: form.adminId,
    }

    try {
      await toast.promise(ApiHelper.createObjectTenant(id, tenant), {
        loading: 'Adding tenant...',
        success: 'Tenant Added.',
        error: 'Could not add tenant.',
      })
      setModalOpen(false)
      if (refreshRef.current) {
        refreshRef.current()
      }
    } catch (err) {}
  }

  const onEditTenantPrice = async (form) => {
    try {
      await toast.promise(
        ApiHelper.updateTenantPrice(id, selectedTenant.tenantId, form),
        {
          loading: 'Saving price...',
          success: 'Tenant pricing saved.',
          error: 'Could not save tenant pricing.',
        }
      )
      setSelectedTenant(null)
      if (refreshRef.current) {
        refreshRef.current()
      }
    } catch (err) {}
  }

  return (
    <>
      <FilteredTable
        apiName={['showObjectTenants', id]}
        placeholder={t('application:search')}
        queryProps={{ refetchOnWindowFocus: false }}
        dataApi={() => ApiHelper.showObjectTenants(id)}
        filterDebounce={true}
        initialSort={{ id: 'primaryDomain', desc: 'true' }}
        removeFooter={true}
        refreshRef={refreshRef}
        showRefreshButton={true}
        tableHeaders={tableHeaders(onTenantAccessAction)}
      >
        <Button onClick={() => setModalOpen(true)}>Add Tenant</Button>
      </FilteredTable>
      <FlexibleModal size="modal-md" visible={modalOpen}>
        <div className="flex flex-col space-y-2">
          <TenantSearchTable id={id} onSubmit={onSubmitTenantAccess} />
          <div className="flex justify-end">
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </div>
        </div>
      </FlexibleModal>
      {selectedTenant && (
        <EditPrice
          title={'Tenant price'}
          object={selectedTenant}
          onSubmit={onEditTenantPrice}
          onCancel={(e) => setSelectedTenant(null)}
        />
      )}
    </>
  )
}

const tableHeaders = (onAction) => [
  // {
  //   Header: 'ID',
  //   i18n: 'showObject:objectLogsTable.id',
  //   accessor: 'tenantId',
  //   width: 80,
  // },
  {
    Header: 'Actions',
    i18n: 'indexObjects:objectGroupsTable.actions',
    accessor: 'nothing',
    width: 100,
    Cell: (props) => (
      <MenuButton
        title="More"
        small={true}
        pinRight={false}
        options={[
          {
            key: 'price',
            title: 'Edit price',
            onClick: () => onAction('price', props.row.original),
          },
          {
            key: 'activation',
            title: props.row.original.active ? 'Deactivate' : 'Activate',
            onClick: () => onAction('activation', props.row.original),
          },
          {
            key: 'delete',
            title: 'Delete',
            onClick: () => onAction('delete', props.row.original),
          },
        ]}
      />
    ),
  },
  {
    Header: 'Active',
    i18n: 'showObject:tabs.active',
    accessor: 'active',
    width: 80,
    Cell: (props) => (
      <CheckCircleIcon
        className={`mx-auto h-5 w-5 ${
          props.row.original.active ? 'text-green-400' : 'text-gray-200'
        }`}
      />
    ),
  },
  {
    Header: 'Domain',
    i18n: 'showObject:tabs.domain',
    accessor: 'primaryDomain',
    width: 180,
  },
  {
    Header: 'Name',
    i18n: 'showObject:objectLogsTable.name',
    accessor: 'name',
    width: 200,
  },
  {
    Header: 'Price',
    i18n: 'showObject:tabs.price',
    accessor: 'price',
    width: 70,
    Cell: ({ row: { original } }) => (
      <div className="text-right">{original.price}</div>
    ),
  },
  // {
  //   Header: 'Online Payment',
  //   accessor: 'onlinePayment',
  //   width: 150,
  //   Cell: (props) => (props.row.original.onlinePayment ? 'Yes' : 'No'),
  // },
  {
    Header: 'Online Prc',
    accessor: 'onlinePrice',
    width: 100,
    Cell: ({ row: { original } }) => (
      <div className="text-right">
        {original.onlinePayment ? original.onlinePrice : ''}
      </div>
    ),
  },
  // {
  //   Header: 'Can Invoice',
  //   accessor: 'canInvoice',
  //   width: 110,
  //   Cell: (props) => (props.row.original.canInvoice ? 'Yes' : 'No'),
  // },
  {
    Header: 'Invoice Prc',
    accessor: 'invoicePrice',
    width: 110,
    Cell: ({ row: { original } }) => (
      <div className="text-right">
        {original.canInvoice ? original.invoicePrice : ''}
      </div>
    ),
  },
  {
    Header: 'Date',
    i18n: 'showObject:tabs.createdBy',
    accessor: 'createdBy',
    width: 280,
    Cell: (props) => props.row.original.createdBy,
  },
  {
    Header: 'Date',
    i18n: 'showObject:tabs.createdAt',
    accessor: 'createdAt',
    width: 180,
    Cell: (props) => toLocalTime(props.row.original.createdAt),
  },
]

export default ObjectTenantAccessTable

import React, { useRef, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  Card,
  FilteredTable,
  ObjectName,
  MenuButton,
  IndeterminateCheckbox,
  Spinner,
  Tag,
} from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { prefetchQuery } from 'helpers/QueryHelper'
import {
  ObjectActive,
  ObjectOnlineState,
  ObjectPublic,
} from 'components/shared/Objects'
import UserTypeIcon from 'components/shared/Users/UserTypeIcon'

const AdminUserObjectsView = (props) => {
  //  --- Variables ---
  let history = useHistory()
  const { t } = useTranslation()
  const tableRef = useRef()
  const refreshRef = useRef()
  const userId = props.match.params.id

  const { data: user } = useQuery(
    ['user', userId],
    () => ApiHelper.showUser(userId),
    {
      refetchOnWindowFocus: false,
    }
  )

  const onRemoveDelegationBulk = useCallback(async () => {
    const objectIds = tableRef.current
      .getSelectedRows()
      .map((r) => r.original.objectId)
    await toast.promise(ApiHelper.bulkRemoveUserDelegation(userId, objectIds), {
      loading: 'Removing delegation...',
      success: 'Delegation rights updated.',
      error: 'Could not remove delegation rights.',
    })

    if (refreshRef.current) refreshRef.current()
  }, [tableRef, refreshRef, userId])

  const onRemoveAccessBulk = useCallback(async () => {
    const objectIds = tableRef.current
      .getSelectedRows()
      .map((r) => r.original.objectId)
    await toast.promise(ApiHelper.bulkRemoveUserAccess(userId, objectIds), {
      loading: 'Removing access...',
      success: 'Access rights updated.',
      error: 'Could not remove access rights.',
    })

    if (refreshRef.current) refreshRef.current()
  }, [tableRef, refreshRef, userId])

  //  --- Response ---
  return (
    <div className="flex h-full flex-col">
      <UserCard user={user} />
      <Card cardType="primary-content" className="my-4 h-full">
        <FilteredTable
          apiName={['indexUserObjects', props.match.params.id]}
          dataFormatter={(data) =>
            data.map((row) => ({ ...row, overrideOnClick: [0] }))
          }
          dataApi={() => ApiHelper.showUserObject(props.match.params.id)}
          queryProps={{
            refetchOnWindowFocus: false,
          }}
          tableRef={tableRef}
          refreshRef={refreshRef}
          filterCacheKey={['indexUserObjects', props.match.params.id]}
          initialSort={{ id: 'name', desc: false }}
          placeholder={t('application:search')}
          removeFooter={true}
          scrollRestore={true}
          rowOnClick={(row) => {
            const accessLevel = 'delegate'

            prefetchQuery(['object', row.objectId, { accessLevel }], row)

            history.push(`/objects/${accessLevel}/${row.objectId}`)
          }}
          tableHeaders={tableHeaders}
        >
          <MenuButton
            title={t('application:bulk')}
            options={[
              {
                key: 'rm_admin',
                title: 'Remove delegation',
                onClick: onRemoveDelegationBulk,
              },
              {
                key: 'rm_access',
                title: 'Remove access',
                onClick: onRemoveAccessBulk,
              },
            ]}
          />
        </FilteredTable>
      </Card>
    </div>
  )
}

const UserCard = ({ user }) => {
  return (
    <Card className="mb-4">
      {user ? (
        <div className="flex flex-col">
          <div className="flex flex-row">
            <UserTypeIcon userTypeId={user.userTypeId} />
            <h2 className="text-xl font-semibold ">{user.userName}</h2>
            {user.displayName && (
              <span className="ml-3 text-lg text-gray-600">
                {user.displayName}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-2">
            {user.mobilePhone && (
              <p>
                <span className="text-gray-400">Mobile: </span>
                {user.mobilePhone}
              </p>
            )}
          </div>
          <div className="flex flex-row flex-wrap">
            <Tag property="Tenant" value={user.tenantName || '-'} />
          </div>
        </div>
      ) : (
        <div className="flex">
          <Spinner className="my-auto mr-5" />
        </div>
      )}
    </Card>
  )
}

const tableHeaders = [
  {
    id: 'select',
    resizable: false,
    width: 40,
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div className="px-1">
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
    Cell: (props) => {
      // console.log(props)
      return (
        <div className="px-1">
          <IndeterminateCheckbox {...props.row.getToggleRowSelectedProps()} />
        </div>
      )
    },
  },
  // {
  //   Header: 'ID',
  //   i18n: 'indexObjects:indexObjectsTable.id',
  //   accessor: 'objectId',
  //   width: 70,
  // },
  {
    Header: 'Name',
    i18n: 'indexObjects:indexObjectsTable.name',
    accessor: 'name',
    width: 300,
    Cell: (props) => {
      return <ObjectName object={props.row.original} />
    },
  },
  {
    Header: 'External ID',
    i18n: 'indexObjects:indexObjectsTable.externalId',
    accessor: 'externalId',
    width: 150,
  },
  {
    Header: 'Active',
    accessor: 'active',
    width: 85,
    Cell: (props) => {
      return <ObjectActive object={props.row.original} />
    },
  },
  {
    Header: 'Public',
    accessor: 'isPublic',
    width: 85,
    Cell: (props) => {
      return <ObjectPublic object={props.row.original} />
    },
  },
  {
    Header: 'State',
    i18n: 'indexObjects:indexObjectsTable.state',
    accessor: 'state',
    width: 85,
    Cell: (props) => {
      return <ObjectOnlineState object={props.row.original} />
    },
  },
  {
    Header: 'Access',
    i18n: 'indexObjects:indexAdminObjectsTable.access',
    accessor: 'userAccess',
    Cell: (props) => (props.row.original.userAccess ? 'Yes' : 'No'),
  },
  {
    Header: 'Delegation',
    i18n: 'indexObjects:indexAdminObjectsTable.delegation',
    accessor: 'delegationLevel',
    minWidth: 200,
  },
]

export default AdminUserObjectsView

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Card, FilteredTable } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { prefetchQuery } from 'helpers/QueryHelper'
import UserTypeIcon from 'components/shared/Users/UserTypeIcon'

const AdminObjectTenantsIndexView = () => {
  //  --- Variables ---
  let history = useHistory()
  const { t } = useTranslation()

  //  --- Response ---
  return (
    <Card cardType="primary-content" className="mb-4 h-full">
      <FilteredTable
        apiName="indexObjectTenants"
        dataApi={() => ApiHelper.indexObjectTenants()}
        filterCacheKey="indexObjectTenant"
        initialSort={{ id: 'primaryDomain', desc: false }}
        placeholder={t('application:search')}
        removeFooter={true}
        filterDebounce={true}
        scrollRestore={true}
        rowOnClick={(row) => {
          prefetchQuery('tenant', row.tenantId, row)
          history.push(`/access/tenants/${row.tenantId}`)
        }}
        tableHeaders={tableHeaders}
      />
    </Card>
  )
}

const tableHeaders = [
  // {
  //   Header: 'ID',
  //   i18n: 'indexObjects:objectUsersTable.id',
  //   accessor: 'tenantId',
  //   width: 70,
  // },
  {
    Header: 'Domain',
    i18n: 'indexObjects:objectUsersTable.domain',
    accessor: 'primaryDomain',
    width: 200,
  },
  {
    Header: 'Display Name',
    i18n: 'indexObjects:objectUsersTable.displayName',
    accessor: 'name',
    width: 600,
  },
  {
    Header: 'Type',
    i18n: 'indexObjects:objectUsersTable.type',
    accessor: 'userTypeId',
    width: 50,
    Cell: (props) => <UserTypeIcon userTypeId={1} />,
  },
]

export default AdminObjectTenantsIndexView

import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Button, Spinner, TextArea, TickBox } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { ObjectInformationAttribute } from './ObjectInformationAttribute'
import { useQuery } from '@tanstack/react-query'

const EditObjectInformation = ({ id: objectId }) => {
  //  --- variables ---
  const { t } = useTranslation()
  const { data, refetch, isLoading } = useQuery(
    ['object-info', objectId],
    () => ApiHelper.showObjectInfoBlob(objectId),
    {
      refetchOnWindowFocus: false,
    }
  )

  const [description, setDescription] = useState('')
  const [htmlContent, setHtmlContent] = useState('')
  const [display, setDisplay] = useState(false)
  const [attribs, setAttribs] = useState([])

  useEffect(() => {
    if (data) {
      setDescription(data.description)
      setHtmlContent(data.htmlContent)
      setDisplay(data.active)
      setAttribs(data.attributes)
    }
  }, [data])

  //  --- functionality ---
  const onAddAttrib = useCallback(() => {
    setAttribs((items) => [...items, { label: '', value: '' }])
  }, [setAttribs])

  const onChangeAttrib = useCallback(
    (index, attrib) => {
      setAttribs((items) => {
        let updatedItems = [...items]
        updatedItems[index] = { ...attrib }
        return updatedItems
      })
    },
    [setAttribs]
  )

  const onDestroyAttrib = useCallback(
    (index) => {
      setAttribs((items) => {
        let updatedItems = [...items]
        updatedItems.splice(index, 1)
        return updatedItems
      })
    },
    [setAttribs]
  )

  const onInformationSave = async () => {
    const body = {
      htmlContent: htmlContent,
      description: description,
      active: display,
      attributes: attribs,
    }
    await toast.promise(ApiHelper.saveObjectInfoBlob(objectId, body), {
      loading: 'Saving object info blob...',
      success: 'Saved object info blob',
      error: 'Failed to save info blob.',
    })
    await refetch()
  }

  if (!data || isLoading) {
    return <Spinner />
  }

  //  --- response ---
  return (
    <div className="flex h-full flex-col space-y-4 overflow-y-auto">
      <div className="flex flex-row justify-between">
        <p>Display:</p>
        <TickBox
          value={display}
          onChange={(e) => setDisplay(e.target.checked)}
        />
      </div>
      <div className="flex flex-row justify-between">
        <p>Description:</p>
        <TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="flex flex-row justify-between">
        <p>HTML Content</p>
        <TextArea
          value={htmlContent}
          onChange={(e) => setHtmlContent(e.target.value)}
        />
      </div>

      <div>
        <div className="flex flex-row justify-between border-b-2 pb-1">
          <span>Attributes</span>
          <Button onClick={onAddAttrib}>Add</Button>
        </div>
        {!attribs.length && (
          <div className="py-4 italic text-gray-500">No attributes</div>
        )}
        {attribs.map((attr, index) => (
          <ObjectInformationAttribute
            key={`obj_attr_${index}`}
            attrib={attr}
            index={index}
            onChange={onChangeAttrib}
            onDestroy={onDestroyAttrib}
          />
        ))}
      </div>

      {/* Footer */}
      <div className="flex flex-row justify-end space-x-4">
        <Button
          className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
          onClick={() => {
            setAttribs([])
          }}
        >
          {t('application:cancel')}
        </Button>
        <Button
          className="bg-green-400 text-white hover:bg-green-500"
          onClick={onInformationSave}
        >
          {t('application:submit')}
        </Button>
      </div>
    </div>
  )
}

export { EditObjectInformation }

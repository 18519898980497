import React, { useRef } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const GeoNorgeSearch = (query) => {
  return fetch(`https://ws.geonorge.no/adresser/v1/sok?sok=${query}`)
    .then((res) => res.json())
    .then((r) => r.adresser)
    .then((results) => {
      return results.map((r) => ({
        postOffice: r.poststed,
        zipCode: r.postnummer,
        address: r.adressetekst,
        code: r.kommunenummer,
        municipality: r.kommunenavn,
        coordinates: {
          lat: r.representasjonspunkt.lat,
          lon: r.representasjonspunkt.lon,
        },
      }))
    })
}

const SearchResults = (props) => {
  const { t } = useTranslation()
  const [results, setResults] = useState(null)
  const [value, setValue] = useState()
  const debounceRef = useRef()

  const onChange = (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (debounceRef.current != null) {
      clearTimeout(debounceRef.current)
    }

    if (value != null) {
      debounceRef.current = setTimeout(() => {
        GeoNorgeSearch(value).then(setResults)
      }, 500)
    }
  }, [value, setResults])

  return (
    <div className="mr-3 flex flex-col">
      <div className="flex-shrink pb-3">
        <input
          placeholder={t('createObject:placeholder')}
          className="focus:outline-none w-60 rounded-lg border border-gray-200 px-3 py-2 focus:border-blue-300 lg:w-96"
          onChange={onChange}
          value={value}
        />
      </div>
      <div className="mx flex-grow">
        {results !== null && results.length < 1 && !!value ? (
          <div className="px-2 italic text-gray-600">No results found</div>
        ) : (
          results &&
          results.map((item, key) => (
            <div
              key={key}
              className="cursor-pointer border-b px-4 py-2 hover:bg-gray-100"
              onClick={() => props.onSelect(item)}
            >
              <div className="text-base">
                {item.address} - ({item.zipCode} {item.postOffice})
              </div>
              <div className=" text-xs text-gray-500">{item.municipality}</div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default SearchResults

import { MapPinIcon } from '@heroicons/react/24/solid'
import {
  Button,
  Card,
  Map,
  ObjectActiveSessions,
  ObjectNameInput,
} from 'components'
import { getDate } from 'helpers/DateTimeHelper'
import { useCommandMap } from 'hooks/useCommandMap'
import ObjectCommands from '../SolverAdmin/Objects/ObjectCommands'
import CommandDropdown from '../../containers/SolverAdmin/Objects/CommandDropdown'

const ActiveMapOverview = ({
  object,
  accessLevel,
  disableQR = false,
  disableActiveSessionCount = false,
}) => {
  const commandMap = useCommandMap(object, accessLevel)

  const showCommandDropdown = (commandMap ?? []).length > 8

  return (
    <Card cardType="content">
      <div className="flex flex-col">
        <div className="flex flex-col justify-between sm:flex-row sm:space-x-3">
          <div className="flex w-auto flex-col space-y-4 sm:w-2/3">
            <div className="flex flex-col space-y-2">
              <div className="flex w-full flex-row items-center justify-between">
                <ObjectNameInput object={object} />
                <div className="flex flex-row items-center justify-between gap-x-3">
                  {disableQR || (
                    <Button
                      disabled={object.tags.length < 1}
                      className="mr-2"
                      onClick={() =>
                        window.open(
                          `/objects/${object.objectId}/qr/visit`,
                          '_blank'
                        )
                      }
                    >
                      QR
                    </Button>
                  )}
                  {showCommandDropdown && (
                    <CommandDropdown
                      commandMap={commandMap}
                      accessLevel={accessLevel}
                      id={object.objectId}
                    />
                  )}
                </div>
              </div>
              <p className="text-sm">{`${
                object.isPublic ? 'Public' : 'Private'
              } Object ${
                object.delegationLevel ? `(${object.delegationLevel})` : ''
              }`}</p>
            </div>
            <div className={'flex flex-row border-b px-2 pb-2 text-lg'}>
              <MapPinIcon className="mt-1 mr-2 h-5 w-5 text-gray-500" />
              <span>
                {object.latitude}, {object.longitude}
              </span>
            </div>
            {disableActiveSessionCount || (
              <ObjectActiveSessions
                id={object.objectId}
                from={getDate()}
                open={true}
              />
            )}
            {showCommandDropdown || (
              <ObjectCommands
                commandMap={commandMap}
                accessLevel={accessLevel}
                id={object.objectId}
              />
            )}
          </div>
          <div className="z-0 mt-4 flex flex-row sm:mt-0 sm:w-1/3">
            <div
              className="flex w-full overflow-hidden rounded-lg"
              style={{ minHeight: 150 }}
            >
              {<Map center={{ lat: object.latitude, lon: object.longitude }} />}
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ActiveMapOverview

import React, { useCallback, useState } from 'react'
import { Button, FilteredTable, MenuButton, ToggleInput } from 'components'
import { CreateObjectSchedule, DeleteObjectSchedule } from '../ObjectSchedules'
import ApiHelper from 'helpers/ApiHelper'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { toLocalTime, withOrWithoutZ } from 'helpers/DateTimeHelper'
import { useCommandMap } from 'hooks/useCommandMap'
import { format, getWeek } from 'date-fns'
import { EditObjectSchedule } from '../ObjectSchedules/EditObjectSchedule'
import toast from 'react-hot-toast'

const ObjectScheduleTable = ({ object, accessLevel, id, ...props }) => {
  //  --- variables ---
  const [visibleModal, setVisibleModal] = useState()
  const [showHistory, setShowHistory] = useState(false)
  const [editSchedule, setEditSchedule] = useState(false)

  const { t } = useTranslation()
  const { refetch } = useQuery(
    ['showObjectSchedules', id],
    () => ApiHelper.showObjectSchedules(id),
    {
      refetchOnWindowFocus: false,
    }
  )
  useQuery(
    ['showObjectScheduleHistory', id],
    () => ApiHelper.showObjectScheduleHistory(id),
    {
      refetchOnWindowFocus: false,
    }
  )

  //  --- Functions ---
  const formatTableData = useCallback(
    (schedules) => {
      const outputData = []

      for (let i = 0; i < schedules.length; i++) {
        const schedule = schedules[i]
        const currentDate = new Date(schedule.executeTime)

        let executeTime2ISO = new Date(
          withOrWithoutZ(schedule.executeTime)
        ).toISOString()
        let executeTime2 =
          executeTime2ISO.substring(0, executeTime2ISO.indexOf('T')) +
          ' ' +
          executeTime2ISO
            .substring(executeTime2ISO.indexOf('T') + 1)
            .slice(0, -5)

        outputData.push({
          ...schedule,
          originalTime: schedule.executeTime,
          executeTime: toLocalTime(withOrWithoutZ(schedule.executeTime)),
          executeTime2,
          weekDay: t(
            'showObject:tabs.schedulesTab.days.' + format(currentDate, 'EEEE')
          ),
          weekNumber: getWeek(currentDate),
        })
      }

      return outputData
    },
    [t]
  )
  const commandMap = useCommandMap(object, accessLevel)

  const onDeleteSchedule = async (commandId) => {
    await toast.promise(ApiHelper.destroyCommand(commandId), {
      loading: 'Deleting command',
      success: 'Command deleted',
      error: 'Failed to delete command',
    })
    refetch()
  }

  const apiName = showHistory
    ? ['showObjectScheduleHistory', id]
    : ['showObjectSchedules', id]

  const dataApi = showHistory
    ? () => ApiHelper.showObjectScheduleHistory(id)
    : () => ApiHelper.showObjectSchedules(id)

  //  --- response ---
  return (
    <div className="h-full">
      <FilteredTable
        apiName={apiName}
        dataApi={dataApi}
        dataFormatter={formatTableData}
        queryProps={{ refetchOnWindowFocus: false }}
        filterDebounce={true}
        initialSort={{ id: 'executeTime', desc: showHistory }}
        placeholder={t('application:search')}
        removeFooter={true}
        showRefreshButton={true}
        tableHeaders={tableHeaders(setEditSchedule, onDeleteSchedule)}
      >
        <div className="flex items-center">
          <ToggleInput
            checked={!showHistory}
            onChange={() => setShowHistory(!showHistory)}
            textChecked="Future"
            textUnchecked="History"
          />
        </div>
        <Button onClick={() => setVisibleModal('create')}>Create</Button>
        <Button onClick={() => setVisibleModal('delete')}>Delete</Button>
      </FilteredTable>
      <CreateObjectSchedule
        commandMap={commandMap}
        objectId={id}
        refetch={refetch}
        setVisible={setVisibleModal}
        visible={visibleModal === 'create'}
      />
      {editSchedule && (
        <EditObjectSchedule
          commandMap={commandMap}
          objectId={id}
          refetch={refetch}
          setSchedule={setEditSchedule}
          schedule={editSchedule}
        />
      )}
      <DeleteObjectSchedule
        objectId={id}
        refetch={refetch}
        setVisible={setVisibleModal}
        visible={visibleModal === 'delete'}
      />
    </div>
  )
}

const tableHeaders = (setEditSchedule, onDeleteSchedule) => [
  {
    Header: 'Actions',
    i18n: 'indexObjects:objectGroupsTable.actions',
    accessor: 'nothing',
    width: 100,
    Cell: (props) => (
      <MenuButton
        title="More"
        small={true}
        pinRight={false}
        options={[
          {
            key: 'price',
            title: 'Edit schedule',
            onClick: () => setEditSchedule(props.row.original),
          },
          {
            key: 'delete',
            title: 'Delete',
            onClick: () => onDeleteSchedule(props.row.original.commandId),
          },
        ]}
      />
    ),
  },
  {
    Header: 'Command Name',
    i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: 'command',
    width: 280,
  },
  {
    Header: 'Param',
    accessor: 'commandParam',
    // width: 200,
  },
  {
    Header: 'Execution Time',
    i18n: 'showObject:tabs.schedulesTab.executionTime',
    accessor: 'executeTime',
    width: 180,
  },
  {
    Header: 'UTC Time',
    // i18n: 'showObject:tabs.schedulesTab.executionTime',
    accessor: 'executeTime2',
    width: 180,
  },
  {
    Header: 'Week',
    accessor: 'weekNumber',
    width: 70,
  },
  {
    Header: 'Day',
    accessor: 'weekDay',
    width: 100,
  },
  {
    Header: 'Done',
    accessor: 'done',
    width: 80,
  },
  {
    Header: 'Result',
    accessor: 'result',
    width: 80,
  },
  {
    Header: 'Pick Up By Scheduler',
    accessor: 'pickUpByScheduler',
    width: 190,
  },
  {
    Header: 'Schedule ID',
    i18n: 'showObject:tabs.schedulesTab.scheduleId',
    accessor: 'commandId',
    width: 140,
  },
]

export default ObjectScheduleTable

import React, { useCallback, useState } from 'react'
import cn from 'classnames'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NavbarItem, Icon } from '..'
import config from 'config'
import './Navbar.css'

const Navbar = () => {
  let history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(true)

  const toggleCollapsed = useCallback(() => {
    setCollapsed((prevState) => !prevState)
  }, [])

  return (
    <div
      className={cn(
        'flex min-h-0 flex-1 flex-col bg-white md:h-full md:w-64',
        collapsed ? '' : 'mb-8'
      )}
      style={{ minWidth: 245 }}
    >
      <div
        className={cn(
          'flex h-full flex-col overflow-y-auto p-4',
          collapsed ? 'shadow-none md:shadow-xl' : 'shadow-xl'
        )}
      >
        <div className="mb-2 flex flex-row justify-between md:hidden">
          <button
            className={'w-5 ' + (collapsed ? '' : 'my-auto')}
            onClick={toggleCollapsed}
          >
            <Icon iconName="menu" className="my-2" />
          </button>
          {location.pathname !== '/home' ? (
            <div
              className="mt-1 flex cursor-pointer flex-row items-center text-gray-600 hover:text-blue-600"
              onClick={() => history.goBack()}
            >
              <span>
                <Icon iconName="chev-left" />
              </span>
              <span className="py-0.5">{t('application:back')}</span>
            </div>
          ) : null}
        </div>
        <div
          id="menuItems"
          className={
            'flex h-full flex-col ' + (collapsed ? 'hidden md:flex' : 'flex')
          }
        >
          <div className="nav-item hidden border-b border-gray-200 pb-3 text-left text-2xl font-bold text-gray-600 hover:text-black md:flex">
            <NavLink to="/home">{config.appName}</NavLink>
          </div>
          <div className="nav-item flex-grow space-y-3 text-sm font-semibold">
            {configMenuBodyItems.map((menuItem, i) => (
              <NavbarItem item={menuItem} key={i} />
            ))}
          </div>
          <div className="mt-3 space-y-3 border-t border-gray-200 text-sm font-semibold md:mt-0 md:border-0">
            {configMenuFooterItems.map((menuItem, i) => (
              <NavbarItem item={menuItem} key={i} />
            ))}
            {menuFooterItems.map((menuItem, i) => (
              <NavbarItem item={menuItem} key={i} />
            ))}
          </div>
          <div className="mt-3 hidden border-t border-gray-200 pt-3 text-xs text-gray-600 md:block">
            v{config.version} ({config.build})
          </div>
        </div>
      </div>
    </div>
  )
}

const menuFooterItems = [
  {
    title: 'Log Out',
    i18n: 'layout:sidebarItems.logout',
    href: '/logout',
    linkType: 'navlink',
    icon: 'logout',
  },
]

const configMenuBodyItems = config.menu
const configMenuFooterItems = config.footer || []

export default Navbar

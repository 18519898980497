import React, { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import {
  FilteredTable,
  SmallButton,
  FlexibleModal,
  Button,
  ObjectOnlineState,
} from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { useHistory } from 'react-router-dom'

import ObjectSearchTable from 'containers/ObjectTables/ObjectSearchTable'

const ObjectRelationsTable = ({ id }) => {
  //  --- Variables ---
  const [visible, setVisible] = useState(false)
  const refreshRef = useRef()
  const history = useHistory()
  const { t } = useTranslation()

  //  --- Response ---
  const onDelete = async (objectId) => {
    await toast.promise(ApiHelper.removeMemberFromObject(id, objectId), {
      loading: 'Deleting membership...',
      success: 'Object membership deleted',
      error: 'Failed to delete object membership',
    })

    refreshRef.current()
  }

  const onSubmitObjectRelation = async ({ objectId }) => {
    try {
      await toast.promise(ApiHelper.addMemberToObject(id, objectId), {
        loading: 'Adding object...',
        success: 'Object Added.',
        error: 'Could not add object.',
      })
      setVisible(false)
      if (refreshRef.current) {
        refreshRef.current()
      }
    } catch (err) {}
  }

  return (
    <>
      <FilteredTable
        apiName={['indexChildObjects', id]}
        dataApi={() => ApiHelper.getObjectMembers(id)}
        dataFormatter={(data) =>
          data.map((d) => ({ ...d, overrideOnClick: [6] }))
        }
        queryProps={{ refetchOnWindowFocus: false }}
        rowOnClick={(row) => history.push(`/objects/delegate/${row.objectId}`)}
        filterDebounce={true}
        placeholder={t('application:search')}
        initialSort={{ id: 'name', desc: true }}
        refreshRef={refreshRef}
        removeFooter={true}
        showRefreshButton={true}
        tableHeaders={tableHeaders(onDelete)}
      >
        <Button onClick={() => setVisible(true)}>Add Member</Button>
      </FilteredTable>
      <FlexibleModal size="modal-md" visible={visible}>
        <div className="flex flex-col space-y-2">
          <ObjectSearchTable id={id} onSubmit={onSubmitObjectRelation} />
          <div className="flex justify-end">
            <Button onClick={() => setVisible(false)}>Close</Button>
          </div>
        </div>
      </FlexibleModal>
    </>
  )
}

const tableHeaders = (onDelete) => [
  {
    Header: 'Name',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: 'name',
    width: 280,
  },
  {
    Header: 'External ID',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: 'externalId',
    width: 160,
  },
  {
    Header: 'State',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: 'state',
    width: 85,
    Cell: (props) => {
      return <ObjectOnlineState object={props.row.original} />
    },
  },
  {
    Header: 'Delegation Level',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: 'delegationLevel',
    width: 280,
  },
  {
    Header: 'Delegated By',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: 'delegatedBy',
    width: 280,
  },
  {
    Header: 'Actions',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: '',
    width: 120,
    Cell: (props) => {
      return (
        <SmallButton onClick={(e) => onDelete(props.row.original.objectId)}>
          <Trans i18nKey="application:remove"></Trans>
        </SmallButton>
      )
    },
  },
]
export default ObjectRelationsTable

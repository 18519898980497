const filterDataByQuery = (queryData, query) => {
  if (!query) return queryData

  return queryData.filter((dataInstance) => {
    let dataInstanceValues = Object.values(dataInstance)

    for (let i = 0; i < dataInstanceValues.length; i++) {
      if (
        JSON.stringify(dataInstanceValues[i])
          .toLowerCase()
          .includes(query.toLowerCase())
      )
        return true
    }

    return false
  })
}

const isAuthenticated = () => {
  const authStatus = localStorage.getItem('authStatus')

  if (authStatus === undefined || authStatus !== 'true') return false

  return true
}

const getFooterForColumn = (formula, data, accessor) => {
  var num = 0

  if (data.length === 1) return data[0][accessor]

  switch (formula.toLowerCase()) {
    case 'average':
      var sum = 0

      for (let i = 0; i < data.length; i++) {
        var numberCheck =
          typeof data[i][accessor] === 'number'
            ? data[i][accessor]
            : parseFloat(data[i][accessor])
        sum = sum + numberCheck
      }
      num = sum / data.length
      break

    case 'sum':
      num = 0
      data.forEach((a) => {
        if (!isNaN(parseFloat(a[accessor]))) {
          num += +parseFloat(a[accessor])
        }
      })
      break
    default:
      break
  }

  return num
}

const validateUPN = (upn) => {
  let clean = upn.replace(/\s/g, '')
  if (clean.includes('@') && clean.includes('.')) {
    // email validation
    let at = clean.indexOf('@')
    let dot = clean.lastIndexOf('.')

    if (at < dot && clean.length - dot > 2) {
      return clean
    }
  } else {
    var num = clean.replace(/[^0-9]/g, '')
    if (num.length === 10) {
      let prefix = num.slice(0, 2)
      if (prefix !== '47') {
        return null
      } else {
        num = num.slice(num.length - 8, num.length)
      }
    }

    if (num.length === 8) {
      if (num[0] !== '9' && num[0] !== '4') {
        return null
      }
    }

    if (num.length !== 8) {
      return null
    }

    return `+47${num}`
  }

  return null
}

const validateDomain = (query) => {
  const res = query.match(
    /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
  )
  return res !== null ? res[0] : null
}

const joinStrings = (...strings) => strings.filter(Boolean).join(' ')

export {
  filterDataByQuery,
  isAuthenticated,
  getFooterForColumn,
  validateUPN,
  validateDomain,
  joinStrings,
}

import React, { useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'

const MapPanControl = ({ location }) => {
  const map = useMap()

  useEffect(() => {
    if (location != null) {
      map.setView(location, map.getZoom(), {
        animate: true,
      })
    }
  }, [location, map])

  return null
}

const Map = (props) => {
  const lat = props.center ? props.center.lat : 0
  const lng = props.center ? props.center.lon : 0

  return (
    <div className="h-full w-full">
      <MapContainer
        center={[lat, lng]}
        zoom={13}
        scrollWheelZoom={false}
        style={{ width: '100%', height: '100%' }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MapPanControl location={props.center} />
        {props.center != null && (
          <Marker position={[lat, lng]}>
            {props.address != null ? <Popup>{props.address}</Popup> : null}
          </Marker>
        )}
      </MapContainer>
    </div>
  )
}

export default Map

import React from 'react'
import { Button, TextInput } from 'components'
import { useTranslation } from 'react-i18next'

const ObjectInformationAttribute = ({ index, onChange, onDestroy, attrib }) => {
  //  --- variables ---
  const { t } = useTranslation()

  const handleChange = (e, key) => {
    let item = { ...attrib }
    item[key] = e.target.value
    onChange(index, item)
  }

  //  --- response ---
  return (
    <div className="flex flex-row space-x-3 border-b-2 py-1">
      <TextInput
        value={attrib.label}
        onChange={(e) => handleChange(e, 'label')}
        placeholder="Label"
      />
      <TextInput
        value={attrib.value}
        onChange={(e) => handleChange(e, 'value')}
        placeholder="Value"
      />
      <Button className="mx-auto" onClick={() => onDestroy(index)}>
        {t('application:delete')}
      </Button>
    </div>
  )
}

export { ObjectInformationAttribute }
